.glc-btn.see-all-product {
  width: 6rem;
  height: 2rem;
  font-family: "UniversLTStd bold";
  font-size: 0.5rem;
  text-transform: uppercase;
  border: 3px solid #999;
  background-color: #ffffff;
  color: #999;

  &:hover {
    background-color: #999;
    color: #ffffff;
  }
}

.glc-btn.about-red {
  background-color: #999;
  color: #ffffff;
  border: 3px solid #999;
  padding: 0.6875rem 1.125rem;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  width: 7.5rem;
  height: 3.291875rem;

  &:hover {
    background-color: #ffffff;
    color: #999;
  }
}

.glc-btn.about-white {
  background-color: #ffffff;
  color: #999;
  border: 3px solid #999;
  padding: 0.6875rem 1.5rem;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 2.5rem;
  width: 9.8125rem;
  height: 3.291875rem;

  &:hover {
    background-color: #999;
    color: #ffffff;
  }
}

.glc-btn.news-learn-more {
  font-family: "UniversLTStd bold";
  font-size: 0.5rem;
  position: absolute;
  bottom: 0;
  color: #999;
  border: 4px solid #999;
  background-color: #ffffff;
  padding: 0.4rem;
  outline: none;
  text-transform: uppercase;

  &:hover {
    color: #ffffff;
    background-color: #999;
  }
}

.glc-btn.about-form-send-btn {
  margin: 2rem auto;
  background-color: #999;
  color: #ffffff;
  border: 3px solid #999;
  padding: 0.2rem;
  font-size: 0.4rem;
  font-weight: bold;
  width: 3rem;

  :hover {
    color: #ffffff;
  }
}