.change-bg {
  .grid-one-column {
    height: 55.5vw;
  }

  img.desk {
    position: absolute;
    visibility: visible;
  }

  .img-front {
    opacity: 0;
  }

  img.mob {
    position: relative;
    visibility: hidden;
  }

  .box-border {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  .row {
    font-family: "Seravek";
  }

  .box-border-text .row:nth-child(1) {
    justify-content: flex-start;
    text-transform: uppercase;
  }

  .box-border-text .row:nth-child(2) {
    font-family: "UniversLTStd bold";
    text-transform: uppercase;
  }

  .box-border-text .row:nth-child(3) {
    font-family: "UniversLTStd light";
    text-transform: uppercase;
  }

  .box-border-text .row:nth-child(5) {
    justify-content: flex-start;
  }
}

//mobile
@media screen and (max-width: 767px) {
  .change-bg {
    .grid-one-column {
      height: 104vw;
    }

    img.desk {
      visibility: hidden;
    }

    img.mob {
      visibility: visible;
    }

    .frame-img {
      width: 65% !important;
      top: 35%;
      left: 17%;
      position: absolute;
      height: 20%;
      background-size: contain;
      background-repeat: no-repeat;

      div {
        width: 15%;
        top: 5%;
      }
    }

    .box-border-text {
      padding: 0 1.5rem;

      .row-1 {
        padding-top: 0.5rem;
        font-size: 0.6rem;
        font-weight: 100;
        font-family: "UniversLTStd light";
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0.8;
      }

      .row-2 {
        display: block;
        font-size: 2rem;
        line-height: 1.5rem;
        width: 100%;
        text-align: center;
        margin-top: 0.3rem;
      }

      .row-3 {
        margin: 44% auto 0 auto;
        text-align: center;
        display: block;
      }

      .row-4 {
        margin: 6rem auto 0 auto;
        text-align: center;
        display: block;
      }

      .row-5 {
        margin-top: 0rem;
        text-align: center;

        h4 {
          font-size: 0.6rem;
          border: none;
          opacity: 0.8;
          width: 100%;
          margin: 0;
        }
      }

      .row-6 {
        font-size: 0.6rem;
        text-align: center;
        display: block;
        margin-top: 14%;
      }
    }

    .yellow-div {
      left: 1.8%;
    }

    .blue-div {
      left: 18%;
    }

    .gray-div {
      left: 34.5%;
    }

    .red-div {
      left: 51%;
    }

    .white-div {
      left: 67.2%;
    }

    .green-div {
      left: 84%;
    }
  }
}

//desktop
@media screen and (min-width: 768px) {
  .change-bg .box-border-text {
    margin-top: -55px;

    .row-1 {
      font-size: 0.8rem;
      margin-top: 7%;
    }

    .row-2 {
      font-size: 2rem;
      line-height: 2rem;
      margin-left: 1%;
    }

    .row-3 {
      font-size: 1.2rem;
      text-transform: uppercase;
      width: 100%;
      margin: 0;
      display: block;
      position: relative;
      font-family: "UniversLTStd light";
      font-weight: 100;
      top: 12%;
    }

    .row-4 {
      font-size: 1.2rem;
      text-transform: uppercase;
      width: 100%;
      margin: 0;
      display: block;
      position: relative;
      top: 46%;
      font-family: "UniversLTStd light";
      font-weight: 100;
    }

    .row-5 {
      position: relative;
      display: block;
      top: 12.5%;
      margin: 0;
      width: 100%;
      opacity: 0.6;

      h4 {
        font-size: 0.6rem;
        margin: auto;
        width: 24%;
        height: 1.8rem;
        line-height: 1;
      }
    }

    .row-6 {
      display: block;
      font-size: 0.4rem;
      opacity: 0.8;
      text-align: center;
      margin-top: 5rem;
      position: relative;
      top: -18%;
    }
  }
}

.box-border img.bg {
  cursor: pointer;
  width: 100%;
}

.box-border img.bgg {
  width: 100%;
}

.box-border .box-border-content {
  padding-left: 1rem;
  position: absolute;
  width: 90%;
  top: 10%;
  left: 0;
  transform: translate(5%, 0);
  text-align: center;
  color: #ffffff;

  h1 {
    font-family: "UniversLTStd light";
    font-size: 5vw;
    text-transform: uppercase;
    -webkit-transition: 1.5s;
    transition: 1.5s;
  }
}

.trends p {
  font-family: "Seravek";
  font-size: 1rem;
  padding-top: 3rem;
  visibility: hidden;
}

.homepage-overlay-bg {
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 1.5s;
  outline: 1px solid rgba(255, 255, 255, 0.6);
  outline-offset: -1rem;
}

@media screen and (max-width: 1600px) {
  .homepage-overlay-bg {
    outline-offset: -1.2rem;
  }
}

.frame-img {
  width: 34% !important;
  top: 18%;
  left: 34%;
  position: absolute;
  height: 16%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 5;

  div {
    position: absolute;
    cursor: pointer;
    border-radius: 50%;
    top: 10%;
    height: 49%;
    width: 12%;
  }
}

.yellow-div {
  left: 3%;
}

.blue-div {
  left: 19%;
}

.gray-div {
  left: 36%;
}

.red-div {
  left: 52%;
}

.white-div {
  left: 68%;
}

.green-div {
  left: 85%;
}