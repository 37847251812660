.paint-calculator {
  .container {
    width: 70%;
    max-width: 25rem;
  }

  background-color: #f9f9f9;

  .form-group {
    margin-bottom: 0.7rem;
  }

  label {
    margin-bottom: 0.3rem;
    font-size: 0.5rem;
  }

  .form-control {
    border-radius: 0;
    padding: 0 0.4rem;
  }

  .calculator-title {
    font-size: 0.8rem;
    padding: 10px 0px;
    margin-bottom: 1rem;
  }

  .text-input,
  .select-input {
    height: 1.5rem;
    font-size: 0.5rem;
    border: 1px solid #929292;
    width: 100%;
  }


  .select-for-group {
    position: relative;
  }

  .select-input {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    padding: 5px;
    background-color: transparent;
  }

  @media screen and (max-width: 1400px) {

    .col-md-4,
    .col-md-12 {
      padding-right: 0px;
    }

    select::placeholder {
      font-size: 0.3rem
    }
  }

  .btn-primary {
    background-color: #d4203a !important;
    border: 1px solid #d4203a !important;
    font-size: 0.5rem !important;
    border-radius: 0;
    padding: 0.25rem 0.8rem;
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset, rgb(255, 255, 255) 0px 0px 8px;
  }

  option[disabled]:first-child {
    display: none;
  }

  .select-for-group:after {
    position: absolute;
    right: 15px;
    top: 60%;
    z-index: -1;
    content: url("../../../assets/img/about-arrow.svg");

    @media screen and (max-width: 576px) {
      top: 50%
    }
  }

  .calc-button {
    margin-top: 0.7rem;
    background-color: #d4203a;
    border: 3px solid #d4203a;
    padding: 0.1rem;
    font-size: 0.6rem;
    text-align: center;
    color: #ffffff;
    width: 100%;
  }

  .footer-description {
    font-size: 0.6rem;
    text-align: center;
    padding: 10px;
  }

  .paint-image {
    padding: 15px;
    text-align: center;
  }

  .paint-image img {
    height: 7rem;
  }

  .dimentions {
    height: 1.8rem;
    margin-bottom: 1.5rem;
  }

  .dimension-col,
  .btn-col {
    padding-right: 0;
  }

  .validation-error {
    color: #dc3545;
    margin-top: 2px;
    font-size: 0.5rem;
  }

  .disabled-button {
    background-color: #fb7a8d !important;
    border: 3px solid #fb7a8d;
    cursor: not-allowed;
    margin-top: 0.7rem;
  }

  .clear-button {
    margin-top: 0.7rem;
    background-color: #ffffff;
    border: 1px solid #929292;
    padding: 0.1rem;
    font-size: 0.6rem;
    text-align: center;
    color: #000000;
    width: 100%;
  }
}