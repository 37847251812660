/* Browser capability for placeholder */
*::-webkit-input-placeholder {
  color: #ffffff;
}

*:-moz-placeholder {
  /* FF 4-18 */
  color: #ffffff;
  opacity: 1;
}

*::-moz-placeholder {
  /* FF 19+ */
  color: #ffffff;
  opacity: 1;
}

*:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff;
}

*::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ffffff;
}

*::placeholder {
  /* modern browser */
  color: #ffffff;
}

p {
  font-size: 0.7rem
}

/* Component title position & text*/
.big-title {
  width: 100%;
  height: 50vh;
  display: table;
  align-items: center;
  background-size: cover;

  .title-center {
    width: 100%;
    text-align: center;

    h1 {
      font-family: "UniversLTStd bold";
      color: #908d8b;
      text-transform: uppercase;
      text-align: center;
    }

    p {
      font-family: "UniversLTStd light";
      color: rgba(185, 185, 189, 0.84);

    }
  }

  .title-left {
    padding-left: 10%;
    margin-top: 10%;


    h1 {
      font-family: "UniversLTStd light";
      color: #fff;
      text-transform: uppercase;
      margin: 0;
      line-height: 2rem;
      font-weight: 600;
    }

    p {
      color: #ffffff;
      margin: 0;
    }
  }
}

/* Vertical & Horizontal center */
.vh-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vh-center-2 {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vh-left {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
}

/* Padding-left-right-0 */
.no-padding {
  padding-left: 0;
  padding-right: 0;
}

/* Responsive element style */
.full-width {
  width: 100%;
  height: auto;
}

.page-container {
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
}


.checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  height: 18px;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked~.checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 2px;

    &:after {
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      content: "";
      position: absolute;
      display: none;
    }
  }

  &:hover input~.checkmark {
    background-color: rgb(226, 226, 226);
  }

  input:checked~.checkmark {

    background-color: #EE212D;
    border-color: #EE212D;
  }

  &.reverse {
    input:checked~.checkmark {
      background-color: #FFF;
    }

    .checkmark {
      &:after {
        border: solid #EE212D !important;
        border-width: 0 2px 2px 0 !important;
      }
    }
  }

}

.red-text {
  color: #EE212D;
}