.main-bg {
  background-color: #f9f9f9;
  font-family: 'UniversLTStd light';
}

.padding-40 {
  padding-top: 40px;
}

.page-item-title {
  top: 40%;
  position: absolute;
}

.page-content {
  padding-left: 5%;
  padding-right: 5%;
}

.color-code {
  font-size: 1.2rem;
  opacity: 0.53;
  margin-bottom: 1rem;
}

.brush {
  cursor: pointer;
}


.back-button {
  cursor: pointer;
  margin-top: 4vw;
}

.container-fluid {
  padding-bottom: 2rem;

  .row {
    padding: 0;
    margin: 0;
  }


  .brush-row {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
  }
}

.brush img {
  width: 100%;
  height: auto;
}

.brush-container {
  padding: 25px;
}

.item-bg {
  position: relative;
  z-index: 1;

  img {
    width: 100%;
    height: auto;
  }

  .item-title {
    position: absolute;
    left: 10%;
    bottom: 0;
    transform: translate(0, -50%);
    width: 35%;

    h1 {
      font-family: "UniversLTStd light";
      color: #dfba73;
      text-transform: uppercase;
    }

    p {
      font-family: "UniversLTStd light";
      font-size: 1.625rem;
      color: #ffffff;
    }
  }
}



.full-width-image img {
  width: 100%;
  height: auto;
  transition: 1s;
  outline: 1px solid rgba(255, 255, 255, 0.6);
  outline-offset: -1rem;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.hidden {
  display: none;
}



.color-description img {
  width: 200px;
}

.color-description .back-button {
  cursor: pointer;
}

.color-description .title {
  font-size: 20pt;
  padding: 25px 10px;
}

.color-description .back-button img {
  width: 0.5rem;
}


.slider-container {
  width: 80%;
  margin: auto;
}

.color-title {
  font-size: 1rem;
  line-height: 1;
  margin-top: 25px;
  padding-left: 2rem;
}

.brush {
  margin: 3% 0;
}

.small-images-container {
  padding: 0;
  margin-top: 20px;
  display: -webkit-inline-box;
}

.small-images-container img {
  width: 200px;
  height: 200px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.small-images {
  width: 200px;
  height: 200px;
}