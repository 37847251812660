.blog-tabs {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: relative;
  background: #ffffff;
  padding: 50px;
  width: 90%;
  height: auto;
  box-shadow: 0px 0px 15px rgba(211, 211, 211, 0.7);
  min-width: 240px;
}

.read-more-wrapper {
  margin: 20px 0px;
  width: 100%;
}

.learn-more-container {
  height: 1rem;
  margin-top: 1.5rem
}

.news-title {
  margin-top: 20px;
}

.blog-tabs .content {
  margin-top: 1rem;
  align-items: center;

  h1,
  h2 {
    margin-top: 10px;
    font-family: 'UniversLTStd light';
    font-size: 1.2rem;
    color: #7d7d7d;
    margin-bottom: 0px;
    line-height: 1;
  }

  h6 {
    font-family: 'UniversLTStd light';
    font-size: 0.8rem;
    color: #7d7d7d;
  }

}

.blog-thumb {
  width: 90%;
  width: 100%;
  height: auto;
}

.banner {
  width: 100%;
}

.subtitle {
  color: #A4A4A4;
  font-size: .9rem;
}


// [dir=rtl] {
//     .slick-next {
//         background-image: url("../../assets/img/slider-left-arrow.svg") !important;
//     }

//     .slick-prev {
//         background-image: url("../../assets/img/slider-right-arrow.svg") !important;
//     }
// }

.project-page {
  .slider-container {
    background-color: #D9D9D9;
    padding: 5rem 3rem;
    width: 100%;

    .slick-slider {
      display: grid;
      align-items: center;
    }

    .slick-slide {
      text-align: center;

      img {
        display: inline;
        width: 100%;
        padding: 0 1rem;
      }
    }

    .arrows {
      @media only screen and (max-width: 425px) {
        zoom: .5;
      }

      @media only screen and (max-width: 1024px) {
        zoom: .8;
      }
    }

    .arrows {
      position: absolute;
      border: 0;
    }

    .prev {
      width: 50px;
      height: 80px;
      left: -60px;
      background-image: url("../../assets/img/slider-left-arrow.svg");
    }

    .next {
      width: 50px;
      height: 80px;
      right: -60px;
      background-image: url("../../assets/img/slider-right-arrow.svg");
    }

  }

  .other-projects {

    a {
      text-decoration: none;
    }

    img {
      width: 100%;
      padding: 1rem 0;
    }

    .project-name {
      color: #212529;
      font-size: 18px;
    }
  }
}

.all-projects {

  .slick-slide {
    img {
      width: 100%;
    }
  }

}