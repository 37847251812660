@import "../../responsive.scss";

.modal-dialog {
    margin-top: 15vh;
}

.product-page {

    font-family: Poppins-Regular;

    .vertical-images {
        display: flex;
        flex-direction: column;
        height: 590px;
        min-width: 104px;
        justify-content: flex-start;
        margin-right: 15px;

        img {
            width: 104px;
            height: auto;
            filter: opacity(0.5);
            margin-bottom: 10px;
            border: 1px solid #a2a2a2;
            border-radius: 2px;
        }

        .video-container {
            position: relative;

            .video-preview {
                background-image: url(/assets/img/product/video-play.svg);
                background-repeat: no-repeat;
                background-position: center;
                width: 104px;
                height: 104px;
                position: absolute;
                top: 0;
                background-color: #ffffff70;
                cursor: pointer;
            }
        }

    }

    .mobile-slider {

        margin-left: -15px;
        margin-right: -15px;

        .slick-dots {
            bottom: 15px;

            li button:before {
                font-size: 12px;
                opacity: 1;
                color: #a1a1a1;
            }

            li.slick-active button:before {
                color: #FFF;
                font-size: 16px;
            }
        }

        img {
            width: 100%;
        }
    }

    .product-img {
        overflow: hidden;
        width: 100%;
    }

    .product-detail {

        .product-title {
            font-weight: 500;
            font-family: Poppins-SemiBold;
        }

        .product-subtitle {
            font-weight: 300;
            line-height: 1.3;
        }

        .product-container-size {
            margin-top: 30px;
        }

        button,
        a {
            height: 40px;
            margin: 8px 0;
            width: 100%;

            &.calculate {
                color: #fff;
                background-color: #5E5E60;
                border: 0;
            }

            &.find-store {
                color: #929292;
                background-color: #fff;
                border: 1px solid #929292;
                display: block;
                text-align: center;
                text-decoration: none;
                line-height: 1.8;

                @include media-breakpoint-down(md) {
                    line-height: 2.8;
                }
            }

        }

        .product-color-container {

            .color {
                display: flex;
                align-items: center;
                padding: 5px 0;

                img {
                    margin-right: 10px;
                    margin-left: 10px;
                    width: 40px;
                    height: 40px;
                }

                span {
                    font-size: 12px;
                }
            }
        }
    }

    .calculator-wrapper {
        position: fixed;
        height: 100vh;
        width: 100vw;
        left: 0;
        z-index: 9999;
        background: rgba(0, 0, 0, 0.8);

        .calculator-container {
            position: absolute;
            right: 0;
            // max-width: #{"min(650px, 100vw)"};
            height: inherit;
            background-color: #FFF;



            @media screen and (min-width: 426px) {
                animation-name: appear-ltr;
            }

            animation-duration: .7s;
            animation-fill-mode: forwards;
            padding: 2rem 10vw;
            overflow: auto;

            @media screen and (max-width: 425px) {
                animation-name: appear-dtu;
                height: 80vh;
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;
            }


            .close-icon {
                cursor: pointer;
                width: 25px;
            }

            .calculator-header {
                margin-bottom: 2rem;
                color: #5E5E60;
                font-size: 30px;

                .sub-header {
                    font-size: 16px;
                }
            }

            .calculator-form {

                label {
                    color: #929292;
                }

                .form-group {
                    margin-bottom: .5rem;
                }

                .text-input,
                .select-input {
                    height: 1.5rem;
                    font-size: 0.5rem;
                    border: 1px solid #929292;
                    width: 100%;
                    border-radius: 0;
                }


                .select-for-group {
                    position: relative;
                }

                .select-input {
                    -moz-appearance: none;
                    /* Firefox */
                    -webkit-appearance: none;
                    /* Safari and Chrome */
                    appearance: none;
                    padding: 5px 10px;
                    background-color: transparent;
                    z-index: 1;
                    position: relative;
                }

                .select-for-group:after {
                    position: absolute;
                    right: 15px;
                    top: 60%;
                    z-index: 0;
                    content: url("../../assets/img/about-arrow.svg");

                    @media screen and (max-width: 576px) {
                        top: 55%
                    }
                }

                .custom-btn {
                    margin-top: 0.7rem;
                    width: 100%;
                    padding: 5px 0;
                }

                .calc-button {
                    background-color: #d4203a;
                    border: 1px solid #d4203a;
                    color: #ffffff;
                }

                .disabled-button {
                    background-color: #fb7a8d !important;
                    border: 1px solid #fb7a8d;
                    cursor: not-allowed;
                }

                .clear-button {
                    background-color: #ffffff;
                    border: 1px solid #929292;
                    color: #000000;
                }
            }

            .footer-description {
                margin-top: 1rem;
            }

        }
    }

    .box {
        background-color: #F5F5F5;
        margin-bottom: 20px;
        padding: 20px;
        font-size: 14px;
        color: #5E5E60;

        .title {
            color: #EE212D;
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: bold;
            margin-bottom: 14px;
        }

        ul {
            // list-style: none;
            padding-left: 20px;
            padding-right: 20px;
            font-weight: 400;

            li {
                margin: 10px 0;
            }
        }

        .key {
            color: #5E5E60;
        }

        .value {
            color: #929292;
            margin-bottom: 20px;
        }
    }

    .product-body {
        .heading {
            font-weight: bold;
            color: #212529;
            margin-bottom: 15px;
        }

        p {
            color: #5E5E60;
            font-size: 14px;
            margin-bottom: 30px;
        }

        .tools-container {
            margin-bottom: 30px;
            display: flex;

            .tool {
                display: flex;
                flex-direction: column;
                margin-right: 25px;
                text-align: center;
                color: #5e5e60;
                font-size: 14px;

                img {
                    width: 100px;
                    margin-bottom: 10px;
                }
            }

        }

        .files {
            color: #5E5E60;
            margin-bottom: 20px;

            .file {
                display: flex;
                justify-content: space-between;

                img {
                    margin-right: 10px;
                    margin-left: 10px;
                }

                padding: 5px 0;
                border-bottom: 1px solid #E1E1E1;
            }
        }

    }

    .inspirations-container,
    .colors {

        .heading {
            margin-bottom: 20px;
            font-weight: bold;
        }

        .images {
            margin-bottom: 60px;

            a {
                position: relative;

                @include media-breakpoint-up(md) {
                    margin: 0 5px;
                }

                .overlay {
                    display: none;
                    opacity: .6;
                    background-color: #000000;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    color: #fff;
                    align-items: center;
                    justify-content: center;
                    font-size: 12pt;

                    img {
                        margin: 0 10px;
                    }
                }

                &:hover {
                    .overlay {
                        display: flex;
                    }
                }
            }

            .color {
                width: 200px;
                height: 200px;
            }

            @include media-breakpoint-down(md) {

                a {
                    width: 50%;
                }
            }

            img,
            .color {
                max-width: 200px;

                @include media-breakpoint-down(md) {
                    margin-top: 10px;
                }
            }
        }

    }
}


@keyframes appear-ltr {
    from {
        right: -300px;
    }

    to {
        right: 0;
    }
}

@keyframes appear-dtu {
    from {
        bottom: -300px;
    }

    to {
        bottom: 0;
    }
}


.image-modal {
    .modal-header {
        border: 0;
        padding: 20px 20px 0 0;
        position: absolute;
        z-index: 2;
        right: 10px;
        top: 10px;

        .close {
            font-size: 1rem;
        }
    }

    .modal-body {
        padding: 0;
    }
}


.product-list-page {


    @include media-breakpoint-down(md) {
        .col-product-box {
            &:nth-child(odd) {
                padding-right: 5px;
            }

            &:nth-child(even) {
                padding-left: 5px;
            }
        }
    }

    .mobile-filter-button {
        border: 1px solid #E7E7E7;
        border-radius: 2px 2px 0px 0px;
        height: 100%;
        padding: 0 20px;
        width: 100%;
        margin-right: 10px;
        color: #212529;
    }

    .product-box {
        margin-top: 0;
        border-radius: 2px;

        @include media-breakpoint-down(md) {
            width: 100%;
            height: 400px;
            padding: 10px;
        }

        .new-product-icon {
            width: 30px;
        }

        .product-desc {
            ul {
                * {
                    display: none;
                }
    
                >:nth-child(1),
                >:nth-child(2),
                >:nth-child(3) {
                    display: block;
                }
            }
            

            // >:nth-child(1),
            // >:nth-child(2),
            // >:nth-child(3) {
            //     display: block;
            //     margin-bottom: 0;
            //     text-align: left;

            //     span {
            //         display: block;
            //         margin: 0;
            //         text-align: inherit;
            //         font-size: 14px !important;
            //         color: #929292 !important;
            //         white-space: nowrap;
            //     }

            // }

            // ul {
            //     display: block;

            //     li {
            //         white-space: nowrap;
            //         overflow: hidden;
            //         text-overflow: ellipsis;
            //     }


            //     >:nth-child(1),
            //     >:nth-child(2),
            //     >:nth-child(3) {
            //         font-size: 14px;
            //         color: #929292;
            //         white-space: nowrap;
            //         display: block;
            //         margin: 0;
            //         text-align: inherit;

            //         &:before {
            //             content: "- ";
            //         }
            //     }
            // }
        }

        .compare-error {
            font-size: 12px;
            color: red;
        }

        .product-compare {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .checkbox-wrapper {
                margin: 0;
            }

            a {
                background-color: #F4F4F4;
                color: #5E5E60;
                border: 0;
                width: 40px;
                height: 40px;
                font-size: 12pt;
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }

        &.bordered {
            border: 1px solid #EE212D;
        }

        .compare-icon {
            background-color: #EE212D;
            width: 34px;
            height: 34px;
            position: absolute;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .mobile-filter-opened {
        display: flex !important;
        position: fixed;
        bottom: 0;
        z-index: 999;
        padding: 0;
        height: 100%;
        width: 100%;
        background-color: #00000085;
        align-items: flex-end;

        .product-filters {
            height: 80%;
            width: 100%;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            overflow-y: auto;
            background-color: #FFF;
            padding-top: 60px;

            .mobile-filter-header {
                position: fixed;
                margin-top: -60px;
                text-align: center;
                padding: 15px;
                width: 100%;
                z-index: 99;
                background: #fff;
                border-top-left-radius: inherit;
                border-top-right-radius: inherit;

                .close-icon {
                    position: absolute;
                    left: 20px;
                    top: 20px;
                    cursor: pointer;
                }
            }

            .filter-body {
                background-color: #FFF !important;
            }
        }

    }

    .product-filters {
        font-family: Poppins-Regular;

        .card {
            border: 0;
            border-left: 1px solid #E7E7E7;
            border-right: 1px solid #E7E7E7;
            border-radius: 0;

            .card-header {
                border-top: 1px solid #E7E7E7;
                padding: 0 20px;
                height: 50px;
                background-color: #FFF;
                margin-bottom: 0;

                button {
                    width: 100%;
                    height: 100%;
                    border: 0;
                    color: #5E5E60;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            .filter-body {
                background-color: #FAFAFA;
                padding: 20px;
                border-bottom: 1px solid #E7E7E7;
            }
        }

    }

    .filter-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;
        color: #5E5E60;
        font-size: .5rem;

        .input-wrapper {
            position: relative;

            img {
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
            }

            input {
                height: 48px;
                min-width: 258px;
                border: 1px solid #E7E7E7;
                border-radius: 2px 2px 0px 0px;
                font-size: 14px;
                padding-left: 40px;
                padding-right: 10px;

                &::placeholder {
                    color: #5E5E60;
                    opacity: 1;
                }

                &:-ms-input-placeholder {
                    color: #5E5E60;
                }

                &::-ms-input-placeholder {
                    color: #5E5E60;
                }
            }
        }
    }

    .clear-all-btn {
        border: none;
        color: #5E5E60;
        font-size: 12px;
        right: 15px;
    }

    .selected-filters {
        display: flex;
        margin-bottom: 10px;

        .filter {
            border: 1px solid #E1E1E1;
            border-radius: 2px;
            padding: 5px 10px;
            font-size: .4rem;
            margin-right: 10px;
            color: #A4A5A8;

            img {
                cursor: pointer;
            }
        }
    }
}

.compare-bar {
    font-family: 'Poppins-Regular';
    position: fixed;
    height: 100px;
    width: 100%;
    bottom: 0;
    background-color: #fff;
    z-index: 999;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #5E5E60;

    img {
        margin-right: 20px;
        margin-left: 20px;
    }

    button {
        font-size: 16px;

        &.delete {
            border: 0;
            color: #5E5E60;
        }

        &.compare {
            border: 1px solid #EE212D;
            border-radius: 2px;
            color: #EE212D;
            height: 40px;
            margin-right: 30px;
            padding: 0 10px;
        }
    }


}