// One Column
$gap: 0.4rem;

.grid-one-column {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: $gap 0rem 0rem 0rem;
  padding-bottom: $gap;

}

// Two Column
.grid-two-column {
  display: grid;
  grid-gap: $gap;
  grid-template-columns: repeat(2, 1fr);
  margin: 0rem 0rem $gap 0rem;
}

@media only screen and (max-width: 991px) {
  .grid-two-column {
    grid-template-columns: repeat(1, 1fr);
  }
}

// Three Column
.grid-three-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: $gap;
}

@media only screen and (max-width: 991px) {
  .grid-three-column {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}