.custom-tabs {
  @media screen and (max-width: 991px) {
    ul {
      display: block !important;
      text-align: center;
    }

    .react-tabs__tab-list {
      border-bottom: 0 !important;

    }

    .react-tabs__tab-list li.react-tabs__tab--selected {

      border-bottom: #ffffff 1px solid !important;
    }

    li {
      width: 50% !important;
      text-align: center;
      border-bottom: #a9a9a9 1px solid !important;

    }

    li:nth-child(1) {
      margin-left: 0 !important;
    }


  }

  .react-tabs__tab {
    border-bottom: none;
    list-style: none;
    padding: 0px .5rem;
    cursor: pointer;
  }

  .react-tabs__tab-list {
    font-size: .47rem;
    text-align: center;
    justify-content: center;
    border-bottom: 1px solid #a9a9a9;
    background-color: #ffffff;
    display: flex;
  }

  .react-tabs__tab-list li {
    outline: none;
    margin: 0 auto;
    font-size: .45rem;
    color: #a9a9a9
  }

  .react-tabs__tab-list li:focus {
    box-shadow: unset !important;
    outline: none !important;
  }

  .react-tabs__tab-list li.react-tabs__tab--selected {
    border-top: 0;
    border-radius: 0px;
    outline: none;
    color: #000;
    border: none;
    border-bottom: #ffffff 1px solid;
  }

  .react-tabs__tab-list li.react-tabs__tab--selected::before,
  .react-tabs__tab-list li.react-tabs__tab--selected:focus:before {
    content: "";
    position: absolute;
    left: -2px !important;
    right: unset;
    bottom: 0;
    z-index: 100;
    width: 1px;
    height: 50%;
    background: #a9a9a9;
    transform: skew(20deg, 0deg);
    outline: none;
  }

  .react-tabs__tab-list li.react-tabs__tab--selected::after,
  .react-tabs__tab-list li.react-tabs__tab--selected:focus:after {
    content: "";
    position: absolute;
    right: 2px !important;
    left: unset;
    bottom: 0;
    z-index: 100;
    width: 1px;
    height: 50%;
    background: #a9a9a9;
    transform: skew(20deg, 0deg);
    outline: none;
  }
}

.products-tabs {
  .products-mini-tabs {
    @media screen and (max-width: 991px) {


      .react-tabs__tab-list {
        border-bottom: 1px solid #a9a9a9 !important;
        padding: 0px .5rem !important;
        text-align: center;
        justify-content: center;
        display: flex;

        li.react-tabs__tab--selected {
          border-bottom: 5px solid #ffffff !important;
        }

        li {
          margin-left: 0 !important;
          width: 33% !important;
          font-size: 0.45rem !important;
          border-bottom: 0 !important
        }
      }

      .react-tabs__tab-list li.react-tabs__tab--selected::before,
      .react-tabs__tab-list li.react-tabs__tab--selected:focus:before {
        left: -2px !important;
        bottom: -4px !important;
      }

      .react-tabs__tab-list li.react-tabs__tab--selected::after,
      .react-tabs__tab-list li.react-tabs__tab--selected:focus:after {
        right: 2px !important;
        bottom: -4px !important;
      }
    }
  }
}