$scale_img: 1.1;

.inspiration-boxx {
  display: grid;
  grid-gap: 1rem;
  background-color: #f9f9f9;
  grid-template-columns: repeat(2, 1fr);
}

@media only screen and (max-width: 720px) {
  .inspiration-boxx {
    grid-template-columns: repeat(1, 1fr);
  }
}

.insp-page-title {
  position: absolute;
  margin: 24% 6%;
  z-index: 2;

  h1 {
    font-family: "UniversLTStd light";
    font-size: 5vw;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 1rem;
  }

  p {
    font-family: "UniversLTStd light";
    font-size: 1.3rem;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 0.9;
    font-weight: 100;
  }
}

.inspiration-tabs {
  margin: 6% !important;
  padding: 3%;
  box-shadow: 0px 0px 15px rgba(211, 211, 211, 0.7);
}

.scale {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.scale img.bg {
  width: 100%;
  -webkit-transition: 1.5s;
  transition: 1.5s;
}

.scale:hover img.bg {
  -webkit-transform: scale($scale_img);
  -ms-transform: scale($scale_img);
  transform: scale($scale_img);
}

.scale .scale-content {
  position: absolute;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -10%);
  text-align: center;
  color: #ffffff;

  h2 {
    font-family: "UniversLTStd light";
    font-size: 4vw;
    text-transform: uppercase;
    -webkit-transition: 1.5s;
    transition: 1.5s;
  }

  p {
    font-family: "UniversLTStd light";
    padding-top: 0rem;
    visibility: hidden;
  }
}

.scale:hover .scale-content {
  h2 {
    -webkit-transform: scale(1.4);
    -ms-transform: scale(1.4);
    transform: scale(1.4);
    -webkit-transition: 1.5s;
    transition: 1.5s;
  }

  p {
    visibility: visible;
    -webkit-transition: 1.5s;
    transition: 1.5s;
  }
}

.insp-overlay-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 1.5s;
  transition: 1.5s;
  outline: 1px solid rgba(255, 255, 255, 0.6);
  outline-offset: -0.8rem;
}

.insp {
  .insp-full-img {
    position: relative;
    width: 100%;
    height: 55vw;
    overflow: hidden;

    img {
      width: 100%;
      background-size: cover;
      z-index: -1;
      position: absolute;
      top: 0;
    }

    .bg,
    .vase,
    .white-rose,
    .lamp3,
    .selector-full {
      display: block;
      z-index: 1;
    }

    .bg {
      position: relative;
      width: 120%;
      animation: scalebg1 5s ease;
    }

    .vase,
    .white-rose {
      animation: scaleflowers 5s ease;
      clip: rect(0px, 100vw, 55vw, 0px);
      z-index: 3;
    }

    .lamp3 {
      width: 200%;
      top: -20vw;
      z-index: 2;
      left: -54vw;
      animation: scalelamp3 5s ease;
    }

    .selector-full,
    .selectors {
      z-index: 5;
      animation: selectorMmove 5s ease;
    }

    .onTop {
      z-index: 4;
    }

    .selectors {
      position: absolute;
      height: 2.5vw;
      width: 11vw;
      top: 28.3vw;
      z-index: 6;
      right: 0;
      text-align: left;

      span {
        padding-right: 1.75vw;
        cursor: pointer;
        display: block;
        height: 2vw;
        float: left;
      }
    }
  }

  .inspcontentheader {
    text-align: center;
    margin: 2rem;

    h2 {
      font-size: 1.3rem;
      text-transform: uppercase;
    }
  }

  .insp-content {
    display: flex;
    width: 100%;
    height: 17rem;
    justify-content: center;
    /* align horizontal */
    align-items: center;
    /* align vertical */
    background-color: rgba(249, 249, 249, 1);

    h3 {
      width: 100%;
      font-family: "UniversLTStd light";
      font-size: 0.6rem;
      text-align: center;
    }
  }
}

.insp-data {
  text-align: center;
  font-family: "UniversLTStd light";
  line-height: 0.7rem;
  margin: 5.5rem auto;
}

.insp-slide {
  height: 872px;
  background-color: rgba(249, 249, 249, 1);

  .insp-paint-box {
    display: flex;
    justify-content: center;
    /* align horizontal */
    align-items: center;
    /* align vertical */
  }
}

@keyframes scalebg1 {
  0% {
    width: 150%;
    right: 50%;
  }

  20% {
    width: 100%;
    right: 0;
  }

  60% {
    width: 100%;
    right: 0;
  }

  100% {
    width: 120%;
  }
}

@keyframes scaleflowers {
  0% {
    left: 100%;
    top: 10vw;
    clip: rect(0px, 100vw, 45vw, 0px);
  }

  60% {
    left: 0;
    top: 10vw;
    clip: rect(0px, 100vw, 45vw, 0px);
  }

  100% {
    left: 0;
  }
}

@keyframes scalelamp3 {
  0% {
    width: 100%;
    top: -20vw;
    left: 0;
  }

  60% {
    top: -3vw;
  }

  100% {
    width: 200%;
    top: -20vw;
    z-index: 2;
    left: -54vw;
  }
}

@keyframes selectorMmove {
  0% {
    right: -50vw;
  }

  80% {
    right: -50vw;
  }

  100% {
    right: 0;
  }
}