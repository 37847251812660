.footer {
  width: 100%;
  background-color: #383838;
}

.footer-text {
  text-align: center;
}

.footer-position {
  padding: 5% 12% 2% 10%;
}

.footer {
  p {
    font-size: 0.5rem;
    font-family: "UniversLTStd light";
    color: #ffffff;

    span {
      padding-left: 1rem;
    }
  }

  h5 {
    font-family: "UniversLTStd light";
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.5rem;
  }

  p.copyright {
    font-size: 0.35rem;
  }
}

.icon-phone {
  background-image: url("../../../assets/img/phone-alt-solid.png");
}

.icon-fax {
  background-image: url("../../../assets/img/fax-solid.png");
}

.icon-envelope {
  background-image: url("../../../assets/img/envelope-solid.png");
}

.icon-map {
  background-image: url("../../../assets/img/map-marker-alt-solid.png");
}

.copyright {
  font-family: "UniversLTStd light";
  font-size: 0.9rem;
  opacity: 0.5;
  text-align: center;
  margin-top: 0.5rem;
}

.pages-list {
  li {
    height: 0.8rem
  }
  
  @media screen and (max-width: 576px) {
    li {
      height: 1.2rem
    }
  }
}

.footer_ul_amrc {
  color: #ffffff;
  margin: 0px;
  list-style-type: none;
  font-size: 0.4rem;
  padding: 0;
  display: inline-grid;




  i {
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    width: 0.5rem;
    height: 0.5rem;
  }

  a {
    font-family: "UniversLTStd light";
    color: #ffffff !important;
    display: block;
    position: relative;
    line-height: 0.7rem;
  }
}

.stay-in-touch {
  .footer_ul_amrc {
    color: #ffffff;
    margin: 0px;
    margin-top: 0.5rem;
    list-style-type: none;
    font-size: 0.4rem;
    padding: 0;
    display: inline-grid;

    i {
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      width: 0.5rem;
      height: 0.5rem;
    }

    a {
      font-family: "UniversLTStd light";
      color: #ffffff !important;
      display: block;
      position: relative;
      top: -0.6rem;
      line-height: 0.7rem;
    }
  }
}

.pleft-1 {
  padding-left: 1rem;
  display: inline;
}

.address {
  display: flex;

  .pleft-2 {
    font-size: 0.4rem;
    margin-left: 0.5rem;
  }
}

.footer_ul2_amrc li {
  display: contents;
}

.footer_ul2_amrc li a {
  font-size: 0.8rem;
  padding-right: 1rem;
  color: #ffffff !important;

  :hover {
    text-decoration: none;
  }
}

.boraq-text {
  font-family: "UniversLTStd light";
  color: #eeeeee !important;
}

.boraq-text:hover {
  color: #ffffff !important;
}

.followus {
  min-width: 8rem;
}

.langChange {
  background-color: #383838;
  color: #fff;
  width: 4rem;
  height: 1.2rem;
  font-size: medium;
  margin-left: 0.5rem;

  option {
    font-size: medium;
  }
}