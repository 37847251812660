@font-face {
  font-family: "UniversLTStd";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/Univers-Roman.woff") format("woff");
  /* Modern Browsers */
}

@font-face {
  font-family: "UniversLTStd bold";
  font-weight: bold;
  font-style: normal;
  src: url("./assets/fonts/UniversLTStd-Bold.otf") format("woff");
}

@font-face {
  font-family: "UniversLTStd light";
  font-weight: lighter;
  font-style: normal;
  src: url("./assets/fonts/UniversLTStd-Light.otf") format("woff");
}

@font-face {
  font-family: "Seravek Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Seravek Medium"), url("./assets/fonts/Seravek-Medium.woff") format("woff");
}

@font-face {
  font-family: "Seravek Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Seravek Medium Italic"), url("./assets/fonts/Seravek-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Seravek";
  font-style: normal;
  font-weight: normal;
  src: local("Seravek"), url("./assets/fonts/Seravek.woff") format("woff");
}

@font-face {
  font-family: "Seravek Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Seravek Italic"), url("./assets/fonts/Seravek-Italic.woff") format("woff");
}

@font-face {
  font-family: "Seravek ExtraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Seravek ExtraLight"), url("./assets/fonts/Seravek-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "Seravek ExtraLight Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Seravek ExtraLight Italic"), url("./assets/fonts/Seravek-ExtraLightItalic.woff") format("woff");
}

@font-face {
  font-family: "Seravek Light";
  font-style: normal;
  font-weight: normal;
  src: local("Seravek Light"), url("./assets/fonts/Seravek-Light.woff") format("woff");
}

@font-face {
  font-family: "Seravek Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Seravek Light Italic"), url("./assets/fonts/Seravek-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "Seravek Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Seravek Bold"), url("./assets/fonts/Seravek-Bold.woff") format("woff");
}

@font-face {
  font-family: "Seravek Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Seravek Bold Italic"), url("./assets/fonts/Seravek-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Caveat";
  font-style: normal;
  font-weight: normal;
  src: local("Caveat"), url("./assets/fonts/caveat-regular.woff") format("woff");
}


@font-face {
  font-family: "Bein Ar Black";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/bein-ar-black.ttf") format("ttf");
}

@font-face {
  font-family: "Bein Ar Normal";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/bein-ar-normal.ttf") format("ttf");
}

@font-face {
  font-family: "The Sans Arab";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/TheSansArab-Black.ttf") format("woff");
}

@font-face {
  font-family: "The Sans Arab Bold";
  font-weight: bold;
  font-style: normal;
  src: url("./assets/fonts/TheSansArab-Bold.ttf") format("woff");
}

@font-face {
  font-family: "The Sans Arab Light";
  font-weight: lighter;
  font-style: normal;
  src: url("./assets/fonts/TheSansArab-Light.ttf") format("woff");
}

@font-face {
  font-family: "TheSansArab-Plain";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/TheSansArab-Light.ttf") format("woff");
}

@font-face {
  font-family: "Poppins-Regular";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/Poppins-Regular.ttf") format("woff");
}

@font-face {
  font-family: "Poppins-Bold";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/Poppins-Bold.ttf") format("woff");
}

@font-face {
  font-family: "Poppins-Light";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/Poppins-Light.ttf") format("woff");
}

@font-face {
  font-family: "Poppins-SemiBold";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/Poppins-SemiBold.ttf") format("woff");
}