.ltr {
  .main-navigation-container {
    background: url("./assets/img/header.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  p,
  span {
    span {
      font-family: "UniversLTStd light";
    }
  }

  .insp-boxx .insp-boxxx img {
    margin-right: 0.5rem;
  }

  .insp-text-center {
    margin-left: 0.1rem;
  }

  .Select-arrow-zone {
    right: 0;
  }

  .compare-icon {
      right: 0;
  }

  .change-lang {
    font-family: "The Sans Arab Light";
  }

  @media screen and (min-width: 767px) {

    .search-container {
      padding-right: 0.5rem;
    }
  }
}

.rtl-list {
  // margin-left: 33%;
}

.about-us-list {
  margin-left: 20%
}

.rtl {

  ul li,
  .api-data h5,
  .glc-btn.news-learn-more,
  .glc-btn.about-form-send-btn,
  .stores-container .go-button button,
  .clear-button,
  .circle-group [class*="item"] .v-title,
  .about-us-info-text p,
  #values h1,
  #mission h1,
  .insp-page-title h1,
  #our-vision h1,
  #values p,
  #mission p,
  #our-vision p,
  .scale-content h1,
  .scale-content p,
  .our-promise,
  .our-promise-details,
  .blog-tabs .content h1,
  h2,
  h3,
  h4,
  span,
  p,
  .change-bg .box-border-text .row:nth-child(3),
  div {
    font-family: "The Sans Arab Light" !important;
  }

  text-align: right;
  overflow: hidden;

  .main-navigation-container {
    background: url("./assets/img/header.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .flip-img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .egypt {
    font-size: 1.5rem
  }


  .our-promise-details {
    font-size: 0.9rem
  }

  .colors {
    .color-title {
      padding-left: 0
    }

    .slide-number {
      text-align: left;
    }
  }



  .title-left {
    margin-right: 3rem !important;
    margin-bottom: 7%;
    margin-top: 14%;

    h1 {
      font-family: The Sans Arab Bold !important;
    }

    p {
      color: #ffffff;
      margin-top: 0.45rem;
      font-size: 0.7rem !important;
    }

  }

  .homepage-carousel {
    text-align: right;

    .carousel-item p {
      margin-top: 0.3rem;
    }
  }

  .home-margin:last-child {
    margin-right: 0rem;
  }



  ::-webkit-input-placeholder {
    font-family: "The Sans Arab Light";
  }

  ::-moz-placeholder {
    font-family: "The Sans Arab Light";
  }

  :-ms-input-placeholder {
    font-family: "The Sans Arab Light";
  }

  ::placeholder {
    font-family: "The Sans Arab Light";
  }



  .carousel-inner .slider-vh-left {
    right: 61%;

    left: auto;
  }

  #stylish {
    .navigation-items {
      // justify-content: flex-start;

      a {
        font-family: "The Sans Arab Light";
        font-size: 0.4rem;
      }
    }

    .logo {
      // margin-right: 83vw;
      // margin-top: 10%;
    }

    .fa-calculator {
      margin-left: 5px;
    }

    .searchbox {
      // margin-right: -82%;

      // i {
      //   margin-left: 0.3rem;
      // }
    }

    .navbar-toggler-icon {
      /*   margin-right: -40rem; */

      @media screen and (max-width: 576px) {
        /*         margin-right: -20rem; */

        #stylish .logo {
          margin-top: -15%;
          margin-right: 12rem;
        }
      }
    }
  }

  #rectangle {
    .navigation-items {
      // justify-content: flex-start;

      a {
        font-family: "The Sans Arab Light";
        font-size: 0.4rem;
      }
    }

    .logo {
      margin-right: 590%;
      margin-top: 5%;
      height: 70%;
    }

    .searchbox {
      margin-right: -82%;

      i {
        margin-left: 0.3rem;
      }
    }

    .fa-calculator {
      margin-left: 5px;
    }
  }

  #stylish,
  #rectangle {
    .change-lang {
      ul {
        // justify-content: end;
        // margin-right: 1.2rem;
      }
    }
  }

  // Products Page
  .rotate45 {
    margin-right: 0.7rem;
    margin-top: 0.1rem;
    transform: rotateZ(180deg);

    .icon--expanded {
      transform: rotateZ(-90deg);
    }
  }

  .products-sub-menu {
    padding-right: 20vw;
    padding-left: 1rem;
  }

  .api-data .custom-tabs .react-tabs__tab-list {
    padding-right: 0.5rem;
    text-align: right;
  }

  .products-icons {
    float: right;

    >div {
      margin-left: 0;
      margin-right: 1.5rem;

      &:first-child {
        margin-right: 0.5rem;
      }
    }
  }

  .blog-tabs {
    margin-right: auto;
    height: auto;
    min-width: 240px;

    h1,
    h6,
    p {
      text-align: right;
      font-family: "The Sans Arab Light";
    }
  }

  // Ambience Page
  .amb-item-bg .amb-item-title {
    text-align: right;
    padding-right: 6%;

    p {
      font-family: The Sans Arab light;
    }
  }


  .privacy-blog-tabs {
    margin-right: auto;
  }

  .scale-amb-item .scale-content {
    li {
      text-align: right;
    }

    .scale-content-left {
      margin-right: 1rem;
      text-align: right;

      p {
        font-family: "The Sans Arab Light";
      }

    }

    .frame-yt .boxx p {
      margin-left: 20%;
    }
  }

  .pdfdownload:last-child {
    margin-right: 0.7rem;
  }

  .amb-brochure img {
    margin-left: 0.2rem;
    margin-right: 0;
  }

  .ambiance-h {
    font-family: "The Sans Arab";
  }

  .parent .amb-div .ambiance-scale-content p {
    font-family: "The Sans Arab Light";
  }

  .amb-item-title {
    h1 {
      font-family: "The Sans Arab Bold";
    }

    p {
      margin-top: 1rem;
      font-family: "The Sans Arab";
    }
  }

  .main-bg {
    background-color: #f9f9f9;
    font-family: "The Sans Arab Light";
  }

  .ambiance-scale-content h1.ambiance-h,
  .parent .amb-div .ambiance-scale-content p,
  .parent .colors-div :hover .colors-scale-content p,
  .change-bg .box-border-text .row-1,
  .change-bg .box-border-text .row-2,
  .change-bg .box-border-text .row-3 h4,
  .change-bg .box-border-text .row-5,
  .change-bg .box-border-text .row-6,
  .scale:hover .scale-content p {
    font-family: "The Sans Arab Light";
  }



  // News Page

  .news-learn-more {
    position: relative;
  }

  .glc-btn.news-learn-more {
    float: right;
  }

  .read-more {
    p {
      text-align: justify;
      font-family: "The Sans Arab Light";
    }
  }

  // Footer
  .footer {

    h5,
    a {
      text-align: right;
      font-family: "The Sans Arab Light";
    }

    .footer_ul_amrc,
    .footer_ul2_amrc {
      float: right;
    }

    .pleft-2 {
      font-size: 0.4rem;
      margin-right: 0.5rem;
    }

    .pleft-1:first-of-type,
    .pleft-1:nth-of-type(2) {
      right: 1rem;
      direction: ltr;
    }

    .pt2 {
      margin-bottom: 1rem;
    }

    .copyright {
      margin-left: -391%;

      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
    }
  }

  // Paint Calculator Page
  .paint-calculator .select-for-group:after {
    right: auto;
    left: 15px;
  }

  .calculator-form .select-for-group:after {
    right: auto !important;
    left: 15px;
  }

  // About Us Page
  .about {

    .blog-tabs h1,
    .blog-tabs h6 {
      text-align: center;
      font-family: "The Sans Arab Light";
    }

    .Select-placeholder {
      margin-right: 0.7rem;
    }

    .Select-arrow-zone {
      left: 0.2rem;
    }
  }

  // Stores Page
  .stores-container {
    text-align: right;

    .store-address {
      .fa {
        width: 0.4rem;
        margin-left: 0.2rem;
      }
    }

    .select-for-group:after {
      right: auto;
      left: 15px;
      top: 20%;
      text-align: left;
    }

    .row {
      margin-right: -17px;
      margin-left: -24px;
    }
  }

  .info-window {
    left: 95px;
  }

  .big-title {
    .title-left {
      margin-right: 1rem;

      h1 {
        text-align: right;
      }
    }

    .title-center {
      h1 {
        font-family: "The Sans Arab Bold";
      }

      p {
        font-family: "The Sans Arab Light";
      }
    }
  }

  // Inspiration Page
  .insp-page-title {
    margin-right: 22rem;
    text-align: right;
  }

  .insp-text-center {
    margin-right: 0.1rem;
  }

  .inner-tabs {
    text-align: right;
  }

  .insp-boxx .insp-boxxx img {
    margin-left: 0.5rem;
  }

  .custom-tabs {
    .checker {
      margin: 0;
    }

    .color-combination {
      .insp-button-center h3 {
        margin-right: 0.3rem;
      }
    }
  }

  .small-images-container img {

    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .colors {

    .color-title,
    .color-code {
      padding-right: 2rem;
    }

    .pdf-download-wrapper {
      .pdfdownload:last-child {
        margin-right: 0.7rem;
      }

      img {
        margin-left: 0.2rem;
        margin-right: 0;
      }
    }


    .search-input input {
      margin-left: 0;
      font-family: "The Sans Arab Light" !important;

      i {
        position: absolute;
      }

      input::placeholder {
        color: #ddd;
      }

      input {
        padding-right: 30px;
        width: 100%;
      }
    }
  }

  .interior-bg .interior-title {
    h1 {
      font-family: "The Sans Arab bold";
    }

    p {
      margin-top: 0.6rem;
      font-family: "The Sans Arab light";
    }
  }

  // Search Page
  .search-container {
    .search-input {
      input {
        margin-right: 1.5rem;
      }
    }
  }

  // Trend Page
  .scale-content1 {
    padding: 0 0 0 4vw;
  }

  .scale-content2 {
    p {
      padding: 0 4.5vw 0 0;
    }
  }

  .scale-content3 {
    padding: 0 4vw 0 7vw;
  }



  .color-title {

    padding-right: 2rem;
  }

  .color-description .back-button {
    text-align: left;
  }

  @media screen and (min-width: 1199px) {
    .about .about-content .about-text h1 {
      margin-right: 18%;
    }

    .Brochure {
      padding: 0 5vw 0 5vw;

      img {
        margin-right: 0
      }
    }
  }

  @media screen and (min-width: 767px) {
    .scale-content3 {
      .Brochure {
        padding: 7vw 0 4vw 5vw;

        img {
          margin-left: 0;
        }
      }
    }

    .search-container {
      padding-left: 0.5rem;
    }
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    #rectangle .logo {
      margin-right: 63%;
      margin-top: 2%;
      height: 63%;
    }

    #stylish .logo {
      margin-right: 58vw;
      margin-top: -1%;
    }

    #stylish .navbar-toggler-icon {
      margin-right: -43rem !important;
    }

    .about-text {
      margin-right: 0;
    }

    .about-content p {
      line-height: 0.9rem;
      font-size: 0.8rem !important;
    }
  }

  @media screen and (max-width: 767px) {
    .change-bg .box-border-text .row-2 {
      width: 100%
    }

    .content {
      padding: 0;

      .scale-content2 {
        p {
          padding: 2vw 4.5vw 0px 5vw;
        }
      }
    }

    #stylish .mob-change-lang ul {
      margin-right: 0rem;
      list-style-type: none;
    }

    #rectangle .mob-change-lang ul {
      margin-left: 5rem;
      list-style-type: none;
    }

    .scale-content3 {
      padding: 0vw 5vw 5vw 1vw !important;
    }

    .Brochure {
      padding: 7vw 0 5vw 5vw;

      img {
        margin-left: 3px !important;
        margin-right: 0;

      }
    }

    .insp-page-title {
      margin-right: 14rem !important;

      h1 {
        font-size: 2.4rem;
      }

      p {
        font-size: 0.8rem;
        margin-top: 1rem;
      }
    }

    .flip-img {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }

    .interior-bg .interior-title h1 {
      font-size: 2rem;
    }

    .mob {
      visibility: visible;
    }



    .desktop {
      visibility: hidden;
    }

    .carousel-inner .slider-vh-left {
      right: 55%;

    }

    #stylish .navbar-toggler-icon {
      margin-right: -34rem;
    }

    .footer-position {
      padding: 5% 15% 2% 0%;
    }

    .copyright {
      margin-left: 2.1rem !important;
      font-size: 0.5rem !important;
    }

    .search-input i {
      margin-right: -1.5rem;
    }

    .insp-page-title {
      margin-right: 9rem;
      text-align: right;
    }

    .arrow-mobile-on {
      img {
        float: left
      }
    }
  }


  @media screen and (min-width: 768px) and (max-width: 991px) {
    .mob {
      visibility: visible;
    }

    .desktop {
      visibility: hidden;
    }

    #stylish .navbar-toggler-icon {
      margin-left: 14rem;
    }

    #rectangle .logo {
      margin-right: 27rem;
    }

    p {

      font-size: 0.7rem !important;
    }

    .big-title {
      height: 35vw;
    }

    .colors .slider-container {
      height: 60vw;
    }

    .brush-row {
      margin-top: 8%;
    }

    .title-left {

      margin-top: 5%;
    }

    .about-us-slider {

      margin-top: 6vw;
    }

    .content {
      padding: 0;

      .scale-content2 {
        p {
          padding: 5vw 4.5vw 0px 5vw;
        }
      }
    }

    .scale-content3 {
      padding: 0vw 5vw 5vw 1vw !important;
    }

    .Brochure {
      padding: 0 5vw 5vw;

      img {
        margin-left: 3px !important;
        margin-right: 0;

      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .Brochure {
      padding: 0 5vw 0 5vw;

      img {
        margin-right: 0
      }
    }

    .mob {
      visibility: visible;
    }

    .desktop {
      visibility: hidden;
    }

    #stylish .navbar-toggler-icon {
      margin-right: -43rem;
    }

  }

  @media screen and (max-width: 576px) {
    #stylish {
      .logo {
        margin-top: -15%;
        margin-right: 166%;
      }

      .navbar-toggler-icon {
        margin-right: -150vw;
      }
    }


    #rectangle .logo {
      margin-right: 63%;
      margin-top: 2%;
      height: 63%;
    }



    .about-content {
      .container-fluid {
        padding-right: 50px;
      }

      /*   p {
        font-size: 0.4rem
      } */

      h1 {
        font-size: 2rem
      }
    }
  }

  .compare-icon {
    left: 0;
  }

  .mobile-filter-button {
    margin-left: 10px;
    margin-right: 0;
  }

  @media screen and (max-width: 576px) {
    .col-product-box {
        &:nth-child(odd) {
            padding-left: 5px;
            padding-right: 0;
        }

        &:nth-child(even) {
            padding-right: 5px;
            padding-left: 0;
        }
    }
  }

  .product-detail {

    .product-title {
        font-family: The Sans Arab Bold !important;
    }
  }

}