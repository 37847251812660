.stores-container {
  font-size: 0.6rem;

  .store-link-container {
    text-decoration: none;
    color: black !important;

    &:hover {
      cursor: pointer;
    }
  }



  .container-fluid {
    padding: 2rem;
    width: 100%;
    margin: 0;
  }

  Marker {
    position: relative;

    &::after {
      width: 30px;
      height: 30px;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background-color: black;
      content: ""
    }
  }

  .hq-address-head {
    font-size: 0.7rem;
    padding-bottom: 0.2rem;
    font-weight: bold;
    margin-bottom: 0.2;
  }

  .hq-address {
    font-size: 0.5rem;

    &:after {
      content: '';
      display: block;
      width: 100%;
      margin: 1rem auto;
      background-color: rgba(0, 0, 0, .1);
      height: 1px;
    }
  }

  .stores-item-title {
    h1 {
      font-size: 2.5rem
    }

    h2 {
      font-size: 3rem;

      span {
        font-weight: bolder;
      }
    }
  }

  .map-container {
    height: 100%;

    @media (max-width: 767px) {
      height: 60vh;
    }
  }

  .filter-container,
  .tabs-container {
    padding: 0rem 12%;
  }

  .type-tab-containt {
    height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 5px;
  }

  .type-tab-containt::-webkit-scrollbar {
    width: 7px;
    background-color: #f5f5f5;
  }

  .type-tab-containt::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #bbb;
  }

  .filter-container {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .text-input,
  .select-input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid;

  }

  .select-for-group {
    position: relative;
  }

  .select-input {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    padding: 5px;
    background-color: transparent;
    font-size: .45rem;
  }

  .select-for-group:after {
    position: absolute;
    right: 15px;
    top: 8px;
    z-index: -1;
    content: url("../../assets/img/about-arrow.svg");
  }

  .stores-list {
    padding: 10px;
    margin-top: 0.6rem;
  }

  .go-button {
    width: 50%;
    margin-top: 0.4rem;

    button {
      background-color: #d4203a;
      border: 3px solid #d4203a;
      padding: 0.3rem;
      text-align: center;
      color: #ffffff;
      font-size: 0.35rem;
    }
  }

  .store-address {

    .phone,
    .email,
    .address {
      margin-bottom: 10px;
      font-size: 0.45rem;

      .fa {
        width: 0.4rem;
        margin-right: 0.2rem;


      }

      .fa-map-marker {
        margin-top: 5px
      }
    }

    .store-title {
      font-weight: bold;
      margin-left: 14px;
      margin-bottom: 0.5rem;
    }

    .store-details {
      padding: 10px 0px;
    }
  }
}