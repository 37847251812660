$scale_img: 1.1;

.boxxx {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}



.scale-content1 {
  padding: 0 4vw 0 0;
}

.scale-content2 {
  p {
    padding: 0 0 0 5vw;
  }
}

.scale-content3 {
  padding: 2rem 7vw 2rem 4vw;
}

@media only screen and (max-width: 600px) {
  .boxxx {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  iframe {
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
  }
}

.trends-content {
  width: 100%;
  display: flex;
  text-align: justify;
  align-items: center;

  p {
    font-family: "UniversLTStd light";
    font-size: 0.6rem;
    padding: 2.7rem;
  }
}

.percent {
  width: 33%;
  display: inline-flex;
  padding-left: 1rem;
  padding-right: 1rem;
}

.trends-bg {
  width: 100%;
}

.hide1 {
  display: none;
}

.hide2 {
  display: none;
}

.hide3 {
  display: none;
}

.scale-trends {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  cursor: pointer;
}

.scale-trends img {
  width: 100%;
  -webkit-transition: 1.5s;
  transition: 1.5s;
}

.scale-trends:hover img {
  -webkit-transform: scale($scale_img);
  -ms-transform: scale($scale_img);
  transform: scale($scale_img);
}

.scale-trends .scale-content {
  position: absolute;
  width: 55%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: justify;
  color: #ffffff;

  h2 {
    font-family: "UniversLTStd light";
    font-size: 1.3rem;
    text-transform: uppercase;
    -webkit-transition: 1.5s;
    transition: 1.5s;
    text-align: center;
  }

  p {
    font-family: "Seravek";
    font-size: 2rem;
    padding-top: 3rem;
    visibility: hidden;
  }
}

.scale-trends:hover .scale-content {

  p {
    visibility: visible;
    -webkit-transition: 1.5s;
    transition: 1.5s;
  }
}

.overlay-bg {
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 1.5s;
  transition: 1.5s;
  outline: 1px solid rgba(255, 255, 255, 0.6);
  outline-offset: -30px;
}

.tabss {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: relative;
  background: #ffffff;
  padding: 3rem 2vw;
  padding-bottom: 80px;
  width: 100%;
  height: auto;
  min-width: 240px;
  margin-top: 2%;
}

.tabss input[name="tab-control"] {
  display: none;
}


.tabss ul li label:hover,
.tabss ul li label:focus,
.tabss ul li label:active {
  outline: 0;
}

.adujst-title-center {
  padding: 0rem 2vw
}

.adujst-title-left {
  padding: 3rem 2vw
}

.tabss .slider {
  position: absolute;
  width: 22%;
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);

  .indicator {
    position: absolute;
    width: 50%;
    max-width: 70%;
    margin-left: 1.2rem;
    margin-top: -1.24rem;
    height: 0.639rem;
    background: rgb(255, 255, 255);
    -moz-transform: skew(20deg, 0deg);
    -webkit-transform: skew(20deg, 0deg);
    -o-transform: skew(20deg, 0deg);
    -ms-transform: skew(20deg, 0deg);
    transform: skew(20deg, 0deg);
    border-left: 1px solid rgb(168, 168, 168);
    border-right: 1px solid rgb(168, 168, 168);
    border-bottom: 1px solid rgb(255, 255, 255);
  }
}

.tabss .content {
  text-align: justify;

  section {
    display: none;
    -webkit-animation-name: content;
    animation-name: content;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    line-height: 1.4;
  }
}

.tabss .content section h2 {
  color: #000000;

  :after {
    content: "";
    position: relative;
    display: block;
    width: 30px;
    height: 3px;
    margin-top: 5px;
    left: 1px;
  }
}

.tabss input[name="tab-control"]:nth-of-type(1):checked~ul>li:nth-child(1)>label {
  cursor: default;
  color: #000000;
}

.tabss input[name="tab-control"]:nth-of-type(1):checked~ul>li:nth-child(1)>label svg {
  fill: #000000;
}

@media (max-width: 600px) {
  .tabss input[name="tab-control"]:nth-of-type(1):checked~ul>li:nth-child(1)>label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabss input[name="tab-control"]:nth-of-type(1):checked~.slider {
  -webkit-transform: translateX(41%);
  transform: translateX(41%);
}

.tabss input[name="tab-control"]:nth-of-type(1):checked~.content>section:nth-child(1) {
  display: block;
}

.tabss input[name="tab-control"]:nth-of-type(2):checked~ul>li:nth-child(2)>label {
  cursor: default;
  color: #000000;
}

.tabss input[name="tab-control"]:nth-of-type(2):checked~ul>li:nth-child(2)>label svg {
  fill: #000000;
}

@media (max-width: 600px) {
  .tabss input[name="tab-control"]:nth-of-type(2):checked~ul>li:nth-child(2)>label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabss input[name="tab-control"]:nth-of-type(2):checked~.slider {
  -webkit-transform: translateX(137%);
  transform: translateX(137%);
}

.tabss input[name="tab-control"]:nth-of-type(2):checked~.content>section:nth-child(2) {
  display: block;
}

.tabss input[name="tab-control"]:nth-of-type(3):checked~ul>li:nth-child(3)>label {
  cursor: default;
  color: #000000;
}

.tabss input[name="tab-control"]:nth-of-type(3):checked~ul>li:nth-child(3)>label svg {
  fill: #000000;
}

@media (max-width: 600px) {
  .tabss input[name="tab-control"]:nth-of-type(3):checked~ul>li:nth-child(3)>label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabss input[name="tab-control"]:nth-of-type(3):checked~.slider {
  -webkit-transform: translateX(233%);
  transform: translateX(233%);
}

.tabss input[name="tab-control"]:nth-of-type(3):checked~.content>section:nth-child(3) {
  display: block;
}

.tabss input[name="tab-control"]:nth-of-type(4):checked~ul>li:nth-child(4)>label {
  cursor: default;
  color: #000000;
}

.tabss input[name="tab-control"]:nth-of-type(4):checked~ul>li:nth-child(4)>label svg {
  fill: #000000;
}

@media (max-width: 600px) {
  .tabss input[name="tab-control"]:nth-of-type(4):checked~ul>li:nth-child(4)>label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabss input[name="tab-control"]:nth-of-type(4):checked~.slider {
  -webkit-transform: translateX(330%);
  transform: translateX(330%);
}

.tabss input[name="tab-control"]:nth-of-type(4):checked~.content>section:nth-child(4) {
  display: block;
}

@-webkit-keyframes content {
  from {
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes content {
  from {
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}


//MEDIA QUERİES
@media (max-width: 1000px) {
  .tabss ul li label {
    white-space: initial;
  }

  .tabss ul li label br {
    display: initial;
  }

  .tabss ul li label svg {
    height: 1.5em;
  }
}

@media (max-width: 600px) {
  .tabss ul li label {
    padding: 5px;
    border-radius: 5px;
  }

  .tabss ul li label span {
    display: none;
  }

  .tabss .slider {
    display: none;
  }

  .tabss .content {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 0.6rem;
  }

  .tabss .content section h2 {
    display: block;
  }
}

.trend-color-code,
.trend-color-name {
  // color: #e1dcdc;
  filter: invert(1) grayscale(1) contrast(9);
}

.trend-color-code {
  font-size: 9pt;
}

.Brochure {
  width: 100%;
  font-size: 0.3rem;
  padding: 0 0 0 5vw;

  h6 {
    font-size: 0.4rem;
    font-weight: 800;
  }

  img {
    margin: 0 0.3rem 0 0.3rem;

    &:first-child {
      margin-left: 0;
    }
  }
}


.scale-trends .scale-content.full-width {
  width: 100%;
}

.tabss.tabs-container.space {
  margin-top: 0;
}