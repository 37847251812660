.about {
  .about-bg-title {
    width: 100%;
    height: auto;
  }

  .about-full-img {
    .scale>img {
      width: 100%;
    }

  }

  .showText {
    opacity: 1;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }

  .scale {
    .scale-content {
      width: 80%;
      color: #ffffff;
      text-align: center;

      h2 {
        font-weight: 700;
      }
    }
  }


  .fade-in {
    animation: fadeIn ease 3s;
    -webkit-animation: fadeIn ease 3s;
    -moz-animation: fadeIn ease 3s;
    -o-animation: fadeIn ease 3s;
    -ms-animation: fadeIn ease 3s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .about-row-content {
    padding: 10% 10% 0 10%;

    .our-promise {
      font-size: 1.5rem;
      color: #e60021;
      font-family: "Caveat";
      text-align: justify;
    }

    .our-promise-details {
      opacity: 0.43;
      font-size: 1.1rem;
      font-family: "Caveat";
      text-align: justify;
    }
  }

  .our-mission {
    .content {
      height: 100%;
      align-items: center;
      display: flex;

      p {
        font-size: 0.7rem;
        text-align: justify;
      }
    }
  }

  .our-vision {
    .about-row-content {
      padding: 0 25% 0 25%;
      height: 100%;
      align-items: center;
      display: flex;

      p {
        font-size: 1rem;
        margin-bottom: 0;
        line-height: 1.1rem;
      }

      .egypt {
        font-size: 2rem;
        line-height: 2.2rem;
        font-weight: 700;
      }
    }
  }

  #our-values {
    top: 35%;

    h2 {
      margin-bottom: 1.5rem;
    }
  }

  .our-values {
    p {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }

  .about-video {
    position: relative;
    background-image: url("../../assets/img/about/web-block.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 50%;
    background-position: center;

    @media screen and (max-width: 620px) {
      height: 70vh;
      width: 100%;
      background-image: url("../../assets/img/about/tv-mob.jpg");
    }

    .video-container {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .about-us-iframe {
        width: 340px;
        height: 260px;

        @media screen and (min-width: 1800px) {
          width: 14.5rem;
          height: 9.5rem;
        }

        @media (min-width: 1800px) and (max-width: 1900px) {
          width: 14rem;
          height: 9rem;
        }

        @media (min-width: 1700px) and (max-width: 1800px) {
          width: 13.5rem;
          height: 9rem;
        }

        @media (min-width: 1600px) and (max-width: 1700px) {
          width: 13rem;
          height: 7.5rem;
        }



        @media (min-width: 1400px) and (max-width: 1600px) {
          width: 12.5rem;
          height: 7.5rem
        }

        @media (min-width: 1300px) and (max-width: 1400px) {
          width: 11.5rem;
          height: 7.5rem;
        }

        @media (min-width: 1100px) and (max-width: 1300px) {
          width: 11rem;
          height: 7rem;
        }

        @media (min-width: 950px) and (max-width: 1100px) {
          width: 10rem;
          height: 7rem;
        }

        @media (min-width: 768px) and (max-width: 950px) {
          width: 8.5rem;
          height: 6rem;
        }

        @media (min-width: 620px) and (max-width: 768px) {
          width: 7.5rem;
          height: 5rem;
        }

        @media screen and (max-width: 620px) {
          width: 10.5rem;
          height: 7rem;
          margin-bottom: 0
        }
      }
    }
  }

  .work-with-us {
    margin-top: 5%;
  }

  .text-center {
    text-align: center;
  }

  .box-back-color {
    background-color: #f9f9f9;
  }

  background-color: #f9f9f9;

  .about-full-img {
    width: 100%;
  }

  .react-tabs {
    width: 100%;
  }

  .about-content {
    position: relative;
    background-image: url("../../assets/img/about/markus-spiske.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 50%;
    background-position: center;

    .about-text {
      position: absolute;
      height: 100%;
      top: 0;
      padding-left: 5%;
      margin-top: 11%;

      h1 {
        z-index: 50;
        color: #fff;
      }
    }

    h1 {

      font-size: 2.5rem;
      color: #908d8b;
      text-transform: uppercase;
    }

    p.bold {
      font-family: "UniversLTStd bold";
      font-size: 1.875rem;
      color: #ffffff;
    }

    p.middle {
      font-family: "UniversLTStd";
      font-size: 1.25rem;
      color: #ffffff;
    }

    p {
      font-family: "UniversLTStd light";
      font-size: 0.7rem;
      color: #ffffff;
      margin-bottom: 0.5rem;
      line-height: 1.1rem;
    }
  }
}

.about-boxx {
  background-image: url("../../assets/img/about/Mission-values-background.jpg");
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

@media only screen and (max-width: 600px) {
  .about-boxx {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
  }
}

.about-scale {
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.about-scale img.about-bg {
  width: 100%;
  -webkit-transition: 1.5s;
  transition: 1.5s;
}

.about-scale:hover img.about-bg {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

.about-scale .about-scale-content {
  position: absolute;
  width: 80%;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: justify;
  text-shadow: 1px 2px 1px #000;

  h2 {
    font-family: "UniversLTStd light";
    font-size: 4vw;
    text-transform: uppercase;
    -webkit-transition: 1.5s;
    transition: 1.5s;
  }

  p {
    font-family: "Seravek";
    font-size: 1.4rem;
    padding-top: 3rem;
    visibility: hidden;
  }
}

.about-scale:hover .about-scale-content {
  h2 {
    -webkit-transform: scale(1.4);
    -ms-transform: scale(1.4);
    transform: scale(1.4);
    -webkit-transition: 1.5s;
    transition: 1.5s;
  }

  p {
    visibility: visible;
    -webkit-transition: 1.5s;
    transition: 1.5s;
  }
}

.about-overlay-bg {
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 1.5s;
  transition: 1.5s;
  outline: 1px solid rgba(255, 255, 255, 0.6);
  outline-offset: -30px;
}

.about-title-center {
  height: auto;

  h2 {
    font-size: 1.8rem;
    color: #ec202d;
    text-transform: uppercase;
    text-align: center;
  }

  p {
    font-family: "UniversLTStd light";
    font-size: 0.9rem;
    color: rgba(185, 185, 189, 0.84);
    text-align: justify;
  }

  h4 {
    text-align: center;
    font-size: 1rem;
    margin-top: 2rem;
  }
}

.about-tabs {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: relative;
  background: white;
  padding: 50px;
  padding-bottom: 80px;
  width: 95%;
  height: auto;
  box-shadow: 0 14px 28px rgba(168, 168, 168, 0.25), 0 10px 10px rgba(168, 168, 168, 0.22);
  border-radius: 4px;
  min-width: 240px;
}

.about-tabs input[name="tab-control"] {
  display: none;
}

.about-tabs .content section h2,
.about-tabs ul li label {
  font-family: "UniversLTStd light";
  font-size: 1.1rem;
  color: rgb(168, 168, 168);
  margin-bottom: 0.1rem;

  span {
    text-transform: uppercase;
  }
}

.about-tabs ul {
  list-style-type: none;
  padding-left: 6rem;
  padding-right: 6rem;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  border-bottom: 1px solid rgb(168, 168, 168);

  li {
    box-sizing: border-box;
    flex: 1;
    width: 15%;
    padding: 0 10px;
    text-align: center;

    label {
      transition: all 0.3s ease-in-out;
      color: #929daf;
      padding: 5px auto;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      white-space: nowrap;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
}

.about-tabs ul li label:hover,
.about-tabs ul li label:focus,
.about-tabs ul li label:active {
  outline: 0;
}

.about-tabs .about-slider {
  position: absolute;
  width: 22%;
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);

  .indicator {
    position: absolute;
    width: 62%;
    max-width: 70%;
    margin-left: 31rem;
    margin-top: -2.24rem;
    height: 1.639rem;
    background: rgb(255, 255, 255);
    -moz-transform: skew(20deg, 0deg);
    -webkit-transform: skew(20deg, 0deg);
    -o-transform: skew(20deg, 0deg);
    -ms-transform: skew(20deg, 0deg);
    transform: skew(20deg, 0deg);
    border-left: 1px solid rgb(168, 168, 168);
    border-right: 1px solid rgb(168, 168, 168);
    border-bottom: 1px solid rgb(255, 255, 255);
  }
}

.about-tabs .content {
  margin-top: 8rem;

  section {
    display: none;
    -webkit-animation-name: content;
    animation-name: content;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    line-height: 1.4;
  }
}

.about-tabs .content section h2 {
  color: #000000;

  :after {
    content: "";
    position: relative;
    display: block;
    width: 30px;
    height: 3px;
    margin-top: 5px;
    left: 1px;
  }
}

.about-tabs input[name="tab-control"]:nth-of-type(1):checked~ul>li:nth-child(1)>label {
  cursor: default;
  color: #000000;
}

.about-tabs input[name="tab-control"]:nth-of-type(1):checked~ul>li:nth-child(1)>label svg {
  fill: #000000;
}

@media (max-width: 600px) {
  .about-tabs input[name="tab-control"]:nth-of-type(1):checked~ul>li:nth-child(1)>label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.about-tabs input[name="tab-control"]:nth-of-type(1):checked~.about-slider {
  -webkit-transform: translateX(22%);
  transform: translateX(22%);
}

.about-tabs input[name="tab-control"]:nth-of-type(1):checked~.content>section:nth-child(1) {
  display: block;
}

.about-tabs input[name="tab-control"]:nth-of-type(2):checked~ul>li:nth-child(2)>label {
  cursor: default;
  color: #000000;
}

.about-tabs input[name="tab-control"]:nth-of-type(2):checked~ul>li:nth-child(2)>label svg {
  fill: #000000;
}

@media (max-width: 600px) {
  .about-tabs input[name="tab-control"]:nth-of-type(2):checked~ul>li:nth-child(2)>label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.about-tabs input[name="tab-control"]:nth-of-type(2):checked~.about-slider {
  -webkit-transform: translateX(76%);
  transform: translateX(76%);
}

.about-tabs input[name="tab-control"]:nth-of-type(2):checked~.content>section:nth-child(2) {
  display: block;
}

@-webkit-keyframes content {
  from {
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes content {
  from {
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

//MEDIA QUERİES
@media (max-width: 1000px) {
  .about-tabs ul li label {
    white-space: initial;
  }

  .about-tabs ul li label br {
    display: initial;
  }

  .about-tabs ul li label svg {
    height: 1.5em;
  }
}

@media (max-width: 600px) {
  .about-tabs ul li label {
    padding: 5px;
    border-radius: 5px;
  }

  .about-tabs ul li label span {
    display: none;
  }

  .about-tabs .about-slider {
    display: none;
  }

  .about-tabs .content {
    margin-top: 20px;
  }

  .about-tabs .content section h2 {
    display: block;
  }
}

.square-mision {
  outline: none;
  background: inherit;
  box-shadow: inset 0 0 0 842px rgba(255, 255, 255, 0.6);
  filter: blur(10px);
  filter: brightness(150%);
}

.square-values {
  outline: none;
  background: inherit;
  box-shadow: inset 0 0 0 842px rgba(0, 0, 0, 0.6);
  filter: blur(10px);
  filter: brightness(100%);
}

.about-box {
  width: 55rem;
  height: 59.5rem;
}

.mission {
  p {
    color: rgb(0, 0, 0);
  }

  h2 {
    color: rgb(0, 0, 0);
  }
}

.about-margin {
  max-width: 100%;

  p {
    margin-bottom: 0;
    line-height: 1.3;
  }
}

@media only screen and (max-width: 1023px) {
  .about-margin {
    max-width: 100%;

    p {
      margin-bottom: 0;
      line-height: 1.3;
    }
  }
}

.career {
  z-index: 2;
  position: absolute;
  top: 1.875rem;
  font-size: 1.25rem;
  font-family: "Seravek";
  left: 50%;
}

.become {
  z-index: 2;
  position: absolute;
  top: 1.875rem;
  left: 34.4%;
  font-size: 1.25rem;
  font-family: "Seravek";
}

.about-grid {
  display: grid;
  grid-template-columns: 40.625rem 40.625rem;
  grid-column-gap: 4.5rem;
}

.about-p {
  font-size: 1.25rem;
  font-family: "UniversLTStd light";
  margin-bottom: 0;
}

.about-h3 {
  font-size: 1.875rem;
  font-family: "UniversLTStd light";
}

.about-hr {
  width: 38rem;
  margin-left: 0;
}

.about-grid-elements {
  padding: 1.5rem;
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-h-height {
  height: 5rem;
  margin-bottom: 1.5rem;
}

.about-p-height {
  height: 10.3125rem;
  margin-bottom: 1.5rem;
}

.circle-group {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-gap: 1rem;
  justify-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #ffffff;

  label {
    position: absolute;
    box-sizing: border-box;
    overflow: hidden;
  }

  input {
    display: none;
  }

  [class*="item"] {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "UniversLTStd";
    font-size: 1rem;
    width: 50%;
    height: 50%;
    border: 1px solid transparent;
    transition: all 0.5s ease-in-out;
    z-index: 0;
    cursor: pointer;

    &:hover {
      filter: brightness(85%);
    }

    div:nth-child(2) {
      display: none;
    }

    .v-title {
      font-weight: 700;
      font-size: 1.1rem;
    }

    p {
      font-size: 0.7rem;
      padding: 0;
    }

    &:hover {
      z-index: 1;
    }
  }

  .item1 {
    background-color: #4b3f55;
    top: 0%;
    left: 0%;
  }

  .item2 {
    background-color: #ddbfcb;
    top: 0%;
    left: 50%;
  }

  .item3 {
    background-color: #a66f82;
    top: 50%;
    left: 0%;
  }

  .item4 {
    background-color: #717193;
    top: 50%;
    left: 50%;
  }

  input:checked+[class*="item"] {
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    z-index: 2;

    div:nth-child(1) {
      display: none;
    }

    div:nth-child(2) {
      opacity: 1;
      display: block;
      padding: 2rem;
    }
  }
}

.circle-container {
  padding: 0 !important;
  margin: 0;
  height: 100%;
}

.centered-form {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;

  .form-group {
    margin: auto;
    height: 1.8rem;
  }
}

.text-style {
  width: 20vw;
  height: 1.2rem;
  border: 2px solid rgb(0, 0, 0);
  z-index: 2;
  border-radius: 0;
  font-size: 0.4rem;

  @media screen and (max-width: 576px) {
    width: 40vw;
  }

}

.invalid-feedback {
  font-size: 0.3rem;
  margin-top: 0;
  margin-bottom: 0.25rem;
  display: inline-block;
}

.join-us {
  text-align: center;
  font-size: 1rem;
  margin-top: 2rem;
}