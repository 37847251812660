.about-us-slider {
  $scale_img: 1.1;
  $animation_time: 2s;
  .about-us-info-text {
    color: #fff;
    font-family: 'UniversLTStd light';
    margin-top: 1vw;
    line-height: 1.2rem;
  }


  .slide-component {
    .arrow-right {
      margin-left: 28vw !important;
      margin-top: 0.5vw !important;
    }
  }

  .slider-vh-left {
    position: absolute;
    left: 10%;
    transform: translate(0%, -50%);
    z-index: 2;
  }

  .back {
    position: absolute;
    z-index: -1;
  }

  .slider-obj {
    position: absolute;
    width: 100%;
    height: auto;
    transition: $animation_time;
  }

  .front1 {
    position: absolute;
    top: -25%;
    left: 57%;
    width: 30%;
    height: auto;
  }

  .front2 {
    position: absolute;
    top: -25%;
    left: 79%;
    transform: translate(-50%, 59%);
    width: 15%;
    height: auto;
  }

  .front-2-2 {
    position: absolute;
    top: -25%;
    left: 89%;
    transform: translate(-50%, 59%);
    width: 15%;
    height: auto;
  }

  .full-img {
    width: 100%;
    height: auto;
  }

  .front-3-1 {
    position: absolute;
    top: 0%;
    right: 0;
  }

  .front-3-2 {
    width: 100%;
  }

  .chair {
    left: -100%;
    opacity: 0;
  }

  .table-33 {
    opacity: 0;
    right: -33%;
  }

  .table {
    opacity: 0;
    right: -33%;
  }

  .chair-100 {
    opacity: 0;
    left: -100%;
  }

  .lamb {
    top: -33%;
    opacity: 0;
  }

  .room2 {
    opacity: 0;
    left: -33%;
  }

  .slider-bg {
    width: 150%;
  }

  .active {
    .slider-bg {
      width: 100% !important;
      transition: $animation_time;
    }

    .animation {
      -webkit-transition: $animation_time;
      transition: $animation_time;
      animation: fadeIn ease $animation_time;
      -webkit-animation: fadeIn ease $animation_time;
      -moz-animation: fadeIn ease $animation_time;
      -o-animation: fadeIn ease $animation_time;
      -ms-animation: fadeIn ease $animation_time;
    }

    .chair {
      opacity: 1;
      left: 0;
    }

    .table {
      opacity: 1;
      right: 0;
    }

    .room2 {
      left: 0;
      opacity: 1;
    }

    .room3 {
      top: 42%;
      height: 60%;
    }

    .lamb {
      opacity: 1;
      top: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .side-img {
    position: absolute;
    right: 5%;
    width: 50%;
  }
}