$scale_img: 1.1;

ul,
figure {
  margin: 0;
  padding: 0;
}

.boxx {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid-one-column {
  padding-bottom: 0;
}

.pro-item {
  position: absolute;
  width: 100%;
  min-height: 800px;
  height: auto;
  display: flex;
  flex-direction: column;

  div:nth-child(1) {
    position: relative !important;
  }
}

.scale {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.scale img.bg {
  width: 100%;
  -webkit-transition: 1.5s;
  transition: 1.5s;
}

.scale:hover img.bg {
  -webkit-transform: scale($scale_img);
  -ms-transform: scale($scale_img);
  transform: scale($scale_img);
}

.scale .scale-content {
  position: absolute;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ffffff;

  h1 {
    // text-shadow:2px 2px 2px #000;
    font-family: "UniversLTStd light";
    font-size: 3rem;
    text-transform: uppercase;
    -webkit-transition: 1.5s;
    transition: 1.5s;
  }

  p {
    font-family: "Seravek";
    font-size: 1rem;
    padding-top: 3rem;
    visibility: hidden;
  }

  .home-h1 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 767px) {

  .scale .scale-content {
    p {
      visibility: visible !important;

    }
  }
}

.scale:hover .scale-content {
  p {
    visibility: visible;
    -webkit-transition: 1.5s;
    transition: 1.5s;
  }
}

.overlay-bg {
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 1.5s;
  transition: 1.5s;
  outline: 1px solid rgba(255, 255, 255, 0.6);
  outline-offset: -30px;
}

.scale:hover .overlay-bg {
  background: rgba(0, 0, 0, 0.3);
}

.grid-responsive {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  h3 {
    font-family: "UniversLTStd bold";
    font-size: 1.2rem;
    color: #000000;
  }

  h5 {
    font-family: "Seravek";
    font-size: 0.9rem;
    color: #000000;
  }

  p {
    font-family: "Seravek";
    font-size: 0.65rem;
    color: #000000;
  }
}

.grid-responsive img {
  max-width: 100%;
  height: auto;
}

.vertical-center {
  margin: auto;
}

.col-center {
  display: flex;
  justify-content: center;
}

.col-left {
  display: flex;
  justify-content: flex-start;
}

.icon-group {
  img {
    margin-right: 3vw;
  }
}

.icon-group-small {
  height: 5vw;

  img {
    margin-right: 3vw;
  }
}

.hide-image {
  display: none;
}

.home-product-image {
  width: 26%;
  height: auto;
}

.product-slider {
  .slider-img {
    padding-left: 0.2rem;
  }
}

.product-slider-center {
  display: flex;
  justify-content: center;
  align-items: center;

  .container p,
  .container h3,
  .container div {
    margin-bottom: 0;
  }
}

.home-margin:last-child {
  margin-right: -1rem;
}


.full-in {
  img {
    max-width: 100%;
    height: auto;
  }
}

.product-info-text {
  h3 {
    font-family: "UniversLTStd bold";
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 0;
  }

  h5 {
    font-family: "Seravek";
    font-size: 0.9rem;
  }

  p {
    font-family: "Seravek";
    font-size: 0.5rem;
    margin: 0;
  }
}

.slider-summury p {
  font-size: 0.6rem;
}

.arrow-left-white {
  max-width: 100%;
  height: 64px;
  margin-top: 200%;
  background-color: #ffffff;
  -webkit-mask: url("../../assets/img/homepage/icons/arrow-left.svg") no-repeat center;
  mask: url("../../assets/img/homepage/icons/arrow-left.svg") no-repeat center;
}

.arrow-right-white {
  max-width: 100%;
  height: 64px;
  margin-top: 200%;
  background-color: #ffffff;
  -webkit-mask: url("../../assets/img/homepage/icons/arrow-right.svg") no-repeat center;
  mask: url("../../assets/img/homepage/icons/arrow-right.svg") no-repeat center;
}

.arrow-left {
  background-image: url("../../assets/img/homepage/icons/arrow-slider-left-grey.svg");
}

.arrow-right {
  background-image: url("../../assets/img/homepage/icons/arrow-slider-right-grey.svg");
}

.arrow-left,
.arrow-right {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 0.5rem;
  height: 2rem;
  margin-top: 60%;
}

.slide-component {
  height: 54vw;
}

.custom2-tabs .slide-component {
  height: 40vw;
}

.slide-component-small {
  margin-top: 5%;
  height: 16rem;
}

.carousel-block {
  width: 100%;
  height: auto;
}