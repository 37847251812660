$scale_img: 1.2;
$scale_txt: 1.1;

.main-bg {
  background-color: #f9f9f9;
}

.pdf-download-wrapper {
  margin: 10%;

  h6 {
    font-size: 0.7rem
  }

  a {
    font-size: 0.5rem
  }

  .pdfdownload {
    color: #333;

    img {
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-left: 0.5rem;
    }
  }
}


.colors-overlay-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 1.5s;
  transition: 1.5s;
  outline: 1px solid rgba(255, 255, 255, 0.6);
  outline-offset: -1rem;
}

@media screen and (max-width: 1023px) {
  .parent {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    gap: 0.4rem;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1024px) {
  .parent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    transition: all 1.5s;
    height: 1000px;
    gap: 0.4rem;
  }

  .colors-div {
    padding-right: 0px !important;
    padding-left: 0px !important;

    img.colors-bg {
      object-fit: cover;
      object-position: center;
      overflow: auto;
    }
  }

  .colors-overlay-bg {
    outline-offset: -1rem;
  }

  .colors-h1 {
    font-size: 2vw;
    text-transform: uppercase;
  }

  .colors-header {
    margin-top: 11%;
  }


}

@media screen and (max-width: 1600px) and (min-width: 1281px) {
  .parent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    transition: all 1.5s;
    height: 1400px;
    gap: 0.4rem;
  }

  .colors-div {
    padding-right: 0px !important;
    padding-left: 0px !important;

    img.colors-bg {
      object-fit: cover;
      object-position: center;
      overflow: auto;
    }
  }

  .colors-h1 {
    font-size: 2vw;
    text-transform: uppercase;
  }
}

@media screen and (min-width: 1601px) {
  .parent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    transition: all 1.5s;
    height: 1800px;
    gap: 0.4rem;
  }

  .colors-div {
    padding-right: 0px !important;
    padding-left: 0px !important;

    img.colors-bg {
      object-fit: cover;
      object-position: center;
      overflow: auto;
    }
  }

  .colors-h1 {
    font-size: 2vw;
    text-transform: uppercase;
  }
}

.parent {
  gap: 0.4rem;

  .colors-div {
    position: relative;
    overflow: hidden;
    padding: 0;

    img.colors-bg {
      width: 100%;
      height: 100%;
      -webkit-transition: 1.5s;
      transition: 1.5s;
    }

    .colors-scale-content {
      position: absolute;
      width: 100%;
      top: 45%;
      text-align: center;
      color: #ffffff;

      h1 {
        font-size: 1rem;
      }

      p {
        font-family: "Seravek";
        font-size: 0.6rem;
        visibility: hidden;
      }
    }

    :hover {
      cursor: pointer;

      img.colors-bg {
        -webkit-transform: scale($scale_img);
        -ms-transform: scale($scale_img);
        transform: scale($scale_img);
      }

      .colors-scale-content {
        h1 {
          -webkit-transform: scale($scale_txt);
          -ms-transform: scale($scale_txt);
          transform: scale($scale_txt);
          -webkit-transition: all 1.5s;
          transition: all 1.5s;
        }

        p {
          visibility: visible;
          -webkit-transition: 1.5s;
          transition: 1.5s;
        }
      }
    }
  }

  .colors-div:nth-child(1) {
    grid-area: 1 / 1 / 4 / 4;
  }

  .colors-div:nth-child(2) {
    grid-area: 1 / 4 / 6 / 10;
  }

  .colors-div:nth-child(3) {
    grid-area: 1 / 10 / 6 / 13;
  }

  .colors-div:nth-child(4) {
    grid-area: 4 / 1 / 10 / 4;
  }

  .colors-div:nth-child(5) {
    grid-area: 6 / 4 / 10 / 8;
  }

  .colors-div:nth-child(8) {
    grid-area: 6 / 8 / 13 / 13;
  }

  .colors-div:nth-child(6) {
    grid-area: 10 / 1 / 13 / 4;
  }

  .colors-div:nth-child(7) {
    grid-area: 10 / 4 / 13 / 8;
  }
}

.minus-margin {
  margin-top: -300px;
}

.amb-item-content {
  position: relative;
  background-color: #ffffff;
  width: 100%;
  z-index: 2;
  align-items: center;

  .centered {
    align-items: center;

    .centered-content {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .p-bold {
    font-family: "UniversLTStd light";
    font-size: 1.875rem;
    font-weight: 700;
  }

  p {
    font-family: "UniversLTStd light";
    font-size: 1.625rem;
  }
}

.scale-amb-item img.bg {
  width: 100%;
  -webkit-transition: 1.5s;
  transition: 1.5s;
}

.scale-amb-item:hover img.bg {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

.color-trend-content {
  font-size: 0.6rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5vw;
  }

  .boxx {
    align-items: center;
  }

  .color-cells {
    padding: 1rem;
    margin: 0 1.5rem;
  }

  .color-cell {
    padding-top: 0.2rem;
    height: 1.5rem;
    font-size: 0.35rem;

    .trend-color-code {
      font-size: 0.4rem;
    }

    &:hover {
      cursor: pointer;
    }
  }

  p {
    padding: 0;
    margin: 0;
  }

  .product-slider {

    .arrow-left,
    .arrow-right {
      margin-top: -4rem;
    }
  }
}

.overlay-amb-item-bg {
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 1.5s;
  transition: 1.5s;
  outline: 1px solid rgba(255, 255, 255, 0.6);
  outline-offset: -30px;
}

.brochure {
  font-family: "UniversLTStd light";
}

.colors-items-area {
  margin: 0;
  padding-bottom: 5rem;
}

.color-description {
  font-size: 0.6rem;
  width: 79%;
  margin: 1.5rem auto;
  text-align: justify;
  font-family: "UniversLTStd light";

  p {
    font-family: "UniversLTStd light";
  }
}

.brush {
  cursor: pointer;
}

.brush.selected {
  border: 2px solid #ddd;
}

.brush img {
  width: 100%;
  transform: rotate(20deg);
  height: auto;
}

.brush-container {
  padding: 25px;
}

.padding-0 {
  padding: 0px;
}

.slider-button {
  border: unset;
  font-size: 25pt;
}

.next-button {
  color: #b6b6b6;
  position: absolute;
  top: 45%;
  right: 0px;
  right: -4%;
}

.colors {
  padding-bottom: 20px;

  .back-button {
    color: #b6b6b6;
    position: absolute;
    top: 45%;
    left: -5%;
  }

  .slider-container {
    position: relative;
    margin: 0px 10%;
    height: 55vw;

    .arrow-button {
      position: absolute;
      height: 100%;
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;
      cursor: pointer;
    }

    .right-button {
      right: -7%;
    }

    .left-button {
      left: -10%;
    }
  }

  .slider-container .react-reveal {
    height: 100%;
  }

  .brush-row {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-content: baseline;
    padding: 15px 0;
    margin-bottom: 2.5rem;

    .brush-cell {
      height: 25%;
    }
  }

  .small-images-container {
    margin-top: 20px;
    display: -webkit-inline-box;
  }

  .small-images-container img {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .small-images {
    width: 200px;
    height: 200px;
  }
}

.color-title {
  font-size: 1.8rem;
  height: 2rem;
}

.colors {
  .color-code {
    font-size: 1rem !important;
    margin-bottom: 2rem !important;

    margin-top: -0.5rem !important;
  }

  .color-big-title {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {

  .color-title,
  .color-code {
    padding-left: 0 !important;
  }
}

.color-code {
  font-size: 0.8rem;
  opacity: 0.53;
  line-height: 1;
  margin-bottom: 1.5rem;
  padding-left: 2rem;

  @media screen and (max-width: 767px) {
    padding-left: 0 !important;
  }
}

.full-width-image {
  transition: 1s;
  padding: 0;

  img {
    outline: 1px solid rgba(255, 255, 255, 0.6);
    outline-offset: -1rem;
  }

  .hide {
    opacity: 0;
  }
}

.full-div {
  width: auto;
  height: 700px !important;
}

.slide-number {
  font-size: 0.5rem;
  text-align: right;
  line-height: 2.5rem;
  padding-right: 1rem;
}

.search-input {
  margin-right: 0.6rem;

  i {
    font-size: 0.6rem;
    line-height: 0.7rem;
    color: #ddd;
    position: absolute;
    margin-right: -1.3rem;
  }

  input::placeholder {
    color: #ddd;
  }

  input {
    padding-left: 30px;
    border: none;
    border-bottom: 1px solid #000;
    font-size: 0.5rem !important;
    width: 100%;
    background: transparent;
    display: block;
  }
}