.api-data {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
  margin: auto;

  &:nth-child() {
    margin: auto;
  }

  .light-bg {
    background-color: #F9F9F9
  }

  .zero-padding {
    padding: 0
  }

  &:last-child {
    .bottom-border {
      border-bottom: none;
    }
  }

  h3 {
    font-family: "UniversLTStd light";
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0;
  }

  h5 {
    font-family: "UniversLTStd light";
    font-size: 0.9rem;
    line-height: 0.8rem;
    font-weight: 700;
    margin-bottom: 0.3rem;
  }

  p {
    font-family: "UniversLTStd light";
    font-size: 0.5rem;
    padding: 0.2rem;
    margin-bottom: 0;
  }

  .new-patch-wrapper {
    display: flex;
    justify-content: space-between;

    img {
      width: 80%
    }

    @media only screen and (max-width: 767px) {
      img {
        width: 60%
      }
    }
  }

  .app__toggle {
    margin: 0.5rem 0 0 0;
    padding: 0;

    .app__toggle-text {
      font-size: 0.4rem;
    }
  }

  .custom-tabs {
    .react-tabs__tab-list {
      padding-left: 0.5rem;
      text-align: left;
      display: block;
    }

    .react-tabs__tab-panel {
      padding-left: 0;

    }
  }

  .pdfdownload {
    color: #333;

    .icon-group img {
      margin: 0;
    }
  }
}

.prod-img {
  margin: 0.7rem 1rem 2rem 1rem;

  img {
    height: auto;
    width: 75%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1080px) {
  .api-data {
    flex-direction: column;

    section {
      p {
        font-size: 1rem;
      }
    }

    .prod-img {
      margin: auto;

      img {
        height: auto;
        width: 100%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25%;
      }
    }
  }
}

button {
  font-size: inherit;
  box-shadow: none;
  background: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button:focus {
  outline: 0;
}

.bottom-border {
  padding: 1rem;
  border-bottom: 0.01rem solid #d3d3d3;
}

.app {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__text {
    width: 100%;
  }

  &__collapse {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);

    &--gradient {
      .app__content {
        border-color: transparent;
      }

      &:after {
        pointer-events: none;
        transition: opacity 300ms;
        will-change: opacity;
        opacity: 1;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &--active {
      &:after {
        opacity: 0;
      }
    }
  }

  &__content {
    border: 1px solid transparent;
    position: relative;
    transition: 300ms;

    .tablist {
      margin: 0.5rem 0 0 0;
    }

    .hide {
      display: none;
    }
  }

  &__toggle {
    width: 100%;
    margin: 0 0 0 -0.4rem;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    background-color: #fff;
    transition: background-color 300ms;

    &--active {
      background-color: #ffffff;
    }
  }

  &__toggle-text {
    font-family: "UniversLTStd light";
    font-size: 1.125rem;
  }

  &__image {
    width: 100%;
    height: auto;
    display: block;
  }
}

.icon {
  width: 0.4rem;
  height: 0.4rem;
  border: 1px solid #000000;
  border-radius: 50%;
  padding: 0.05rem;
  margin-left: 0.4rem;
  transition: transform 300ms cubic-bezier(0, 1, 0, 1);
  will-change: transform;

  &--expanded {
    transform: rotateZ(90deg);
  }
}



.patches-icons {
  display: flex;
  justify-content: space-around;

  .patch-col {
    margin-bottom: 1rem;
  }

  >div {
    margin: 0 auto;
    padding: 0;


    &:first-child {
      margin-left: 1rem;
    }

    .spray-gun {
      width: 1.4rem
    }
  }


  text-align: center;

  p {
    font-family: "UniversLTStd light";
    font-size: 0.3rem !important;
  }

  img {
    width: 1.8rem;
    height: 1.8rem;
    margin-top: 5px;
    margin-bottom: 0.5rem;
  }

  margin-top: 10%;
}

.products-icons {
  >div {
    margin: 0 auto;
    padding: 0;
    margin-left: 1.5rem;

    &:first-child {
      margin-left: 1rem;
    }

    .spray-gun {
      width: 1.4rem
    }
  }

  font-size: 0.4rem;
  text-align: center;
  margin-top: 0.2rem !important;
  float: left;

  span {
    font-family: "UniversLTStd light";
  }

  img {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 5px;
    margin-bottom: 0.5rem;
  }

  margin-top: 10%;
}

.rotate45 {
  transform: rotateZ(0deg);
}

.text-line {
  padding: 1rem;
  display: flex;
  align-items: flex-end;
}

.product-inside-tab ul li label:hover,
.product-inside-tab ul li label:focus,
.product-inside-tab ul li label:active {
  outline: 0;
}

@-webkit-keyframes content {
  from {
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes content {
  from {
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

//MEDIA QUERİES
@media (max-width: 1000px) {}



.custom2-tabs {
  >.react-tabs {
    position: relative;
    z-index: 0;

    >ul {
      text-align: center;
      position: absolute;
      top: -1.2rem;
      z-index: -1;
      width: 100%;
      font-size: 0.6rem;

      li {
        width: 16%;
        padding-left: 0;
        padding-right: 0;
        margin-right: 0.2rem;

        @media (max-width: 767px) {
          width: 18%;
        }
      }
    }
  }

  .react-tabs__tab-panel {
    padding: 3%;
    background: #ffffff;
    font-size: 0.45rem;
  }

  background: #ffffff;
  margin: 0 5%;
  box-shadow: 0px 0px 15px rgba(211, 211, 211, 0.7);

  .react-tabs {
    width: 100%;
  }

  .react-tabs__tab--selected {
    border-color: #d4d4d4;
    color: black;
    font-weight: bold
  }

  .react-tabs__tab-list {
    // border-bottom: unset;
    margin: 0px;

    li:not(.react-tabs__tab--selected) {

      color: #a9a9a9;
    }
  }



  >div {
    >ul {
      >li {
        width: 200px;
        text-transform: uppercase;
        outline: none;
        border-radius: 0;
        border: 0;
        font-weight: 500;
      }

      :hover {
        color: #000000;
      }

      .react-tabs__tab:focus {
        box-shadow: none;
        border-color: none;
        outline: none;
      }

      >li:not(.react-tabs__tab--selected) {
        background-color: #e8e8e8;
        color: #a9a9a9;
      }

      >li:nth-child(1).react-tabs__tab--selected {
        box-shadow: 0px 14px 28px rgba(168, 168, 168, 0.25);
      }

      >li:nth-child(2).react-tabs__tab--selected {
        box-shadow: 3px 5px 5px rgba(168, 168, 168, 0.25);
      }
    }
  }
}