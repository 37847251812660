/* Space for component end*/
.small-space {
  padding-bottom: 0.4rem;
}

.space {
  padding-bottom: 1rem;
}

.pt-pb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}


.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pt-120 {
  padding-top: 120px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pt-140 {
  padding-top: 14px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pt-160 {
  padding-top: 160px;
}

.pb-160 {
  padding-bottom: 160px;
}