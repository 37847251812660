* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
}



.firstInit {
  #rectangle {
    visibility: hidden;
  }
}

.scrollDown {
  #stylish {
    visibility: hidden;
  }

  #rectangle {
    visibility: visible;
  }
}

#rectangle .logo {
  height: 70% !important;
}

.scrollUp {
  #rectangle {
    visibility: hidden;
  }

  #stylish {
    visibility: visible;
  }
}

#stylish {
  .mobile-nav .category-title {
    color: #fff !important;
  }

  a {
    color: inherit !important;
    text-decoration: none;
  }

  .category-title {
    margin: 0;
    padding: 0;
    color: #7d7d7d !important;
    /*   color: #000 !important; */
    text-decoration: none !important;
    list-style-type: none;
  }

  .change-lang {
    width: 100%;
    margin-left: 0.5rem;
    line-height: 2vw;


    ul {
      display: flex;
      justify-content: center;
      font-size: 0.4rem;
      margin-top: 0.5rem;
      margin-right: 1rem;
      list-style-type: none;

      li {
        margin: 0 .2rem;
      }

      li:first-child {
        font-family: "The Sans Arab Light";
      }

      span {
        color: #fff !important;
        display: block;
        font-weight: bold;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .mob-change-lang {
    @extend .change-lang;
    margin-top: 1.5rem !important;

    ul {
      font-size: 0.6rem;
    }

  }


  .main-navigation-container {
    justify-content: start;
    align-items: center;
    // padding-bottom: 1rem;
    direction: ltr;
    text-align: left;

    ul,
    li,
    .category-subtitle {
      margin: 0;
      padding: 0;
      color: #7d7d7d !important;
      text-decoration: none !important;
      list-style-type: none;

      &:hover {
        color: #ec202d;
      }
    }
  }

  .main {
    width: 100%;
    position: relative;
    margin: 0 auto;
  }

  nav {
    width: 90%;
    position: relative;
    margin: 0 auto;
  }

  .nav-position {
    // display: flex;
    align-items: center;
    height: 140px;
  }

  .navbar--hidden {
    top: -250px !important;
  }

  .main-navigation-container {
    width: 100%;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    transition: top 0.5s;
    top: 0;
    z-index: 999;
    height: 10.5vw;
  }

  .company-title {
    width: 250px;
    height: 82px;
    background-image: url("../../../assets/img/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    float: left;
  }

  .company-title li a {
    font-size: 1.3rem;
    line-height: 3rem;
  }

  .search-title {
    float: right;
  }

  .navigation-items {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: initial
  }

  .navigation-items ul {
    display: contents;
  }

  .navigation-items ul li {
    font-family: "UniversLTStd";
    text-transform: uppercase;
  }

  .navigation-items>ul>li {
    float: left;
    font-weight: bold;
    padding: 0;
    height: 100px;
    display: flex;
    align-items: center;
    border-bottom-right-radius: 40%;
  }

  .navigation-items>ul>li.sub-svg:hover {
    background: linear-gradient(to bottom, rgba(245, 245, 245, 0.1) 0%, #f6f6f6 60%);
  }

  .navigation-items>ul>li>a {
    // line-height: 3rem;
    padding: 0 10px;
    position: relative;
    z-index: 6;
    // top: 1.5vw;
  }

  .navigation-items>ul>li a {
    font-weight: bold;
    font-size: 0.35rem;
  }

  .submenu-container {
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    padding-right: 75px;
    justify-content: flex-end;
    text-align: center;
    top: 6vw;
    min-width: 50vw;
    height: 7vw;
    // padding: 1% 0 0 0;
    left: auto;
    right: 16vw;
    background-image: url("../../../assets/img/submenu2.svg");
  }


  .navigation-items>ul>li:hover>a+.submenu-container {
    opacity: 1;
    visibility: visible;
  }

  .navigation-items>ul>li:hover>a,
  .navigation-items>ul>li:focus>a {
    color: #ec202d !important;
  }

  .list {
    display: inline-block;
    padding-left: 1rem;
  }

  .category-title h2 {
    font-family: "Seravek";
    color: #7d7d7d;
    display: inline-block;
    font-size: 1.125rem;
  }

  .submenu li a {
    font-size: 0.9rem;
    line-height: 1.8rem;
    color: #555;
  }

  .submenu li a:hover {
    color: #ec202d;
    border-bottom: 1px dotted #03596d;
  }

  .main-navigation-container::after,
  .main-navigation-container::before {
    content: "";
    display: block;
  }

  .main-navigation-container::after {
    clear: both;
  }

  .searchbox {
    text-align: right;
    // margin: 2.5vw 0 0 0;
    padding-right: 5px;

    i {
      font-size: 0.5rem;
      color: #ffffff;
      right: 0;
      // margin-right: 0.4rem;
    }

    input {
      font-size: 0.5rem;
      width: 60%;
      font-family: "Seravek";
      color: #ffffff;
      border: 0;
      outline: 0;
      background: transparent;
      border-bottom: 0.125em solid rgba(255, 255, 255, 0.3);
      line-height: 0.5rem;
      padding: 0;
    }
  }

  .logo {
    width: 80%;
    margin-bottom: 1rem;
  }

  .mobile-nav {
    #mobile-nav-bg {
      background: url("../../../assets/img/mobile-nav.svg");
      background-repeat: no-repeat;
      background-size: 100vw 23vw;

      height: 22vw;
      width: 100vw;
      z-index: 4;
      position: absolute;
      top: 0;
      left: 0;
    }

    z-index: 1010;

    nav {
      position: fixed;
      transition: top 0.5s;
      top: 0;
      z-index: 999;
      height: 20vw;
    }

    .navbar-brand {
      width: 40%;
      z-index: 5;
    }

    .navbar-collapse {
      background-color: #4FA1C4;
      color: #fff;
      opacity: 0.95;
      position: absolute;
      right: 0;
      top: 2px;
      padding: 6rem 0.6rem;
      width: 38vw;
      z-index: 3;
      font-size: 0.6rem;
      max-height: 100vh;
      overflow-y: scroll;

      .dropdown-menu {
        min-width: auto;
      }

      .dropdown-item {
        padding: 0.1rem;
      }

      a {
        font-size: 0.8rem;
        font-weight: 100;
        border-bottom: 0.5px rgba(255, 255, 255, 0.5) solid;
        padding: .5rem 0;
      }

      .nav-link {
        padding: 1rem 0;
      }
    }

    .logo {
      margin: 0;
    }

    .navbar {
      margin: 0px;
      padding: 0px 10%;
      width: 100%;
    }

    button {
      padding: 5px;
      filter: invert(1);
      margin: 5px;
    }

    .navbar-light .navbar-toggler {
      border: none;
      z-index: 5;

      span {
        width: 6vw;
      }
    }
  }

  .fa-calculator {
    margin-right: 5px;
  }
}

#rectangle {
  .mobile-nav {
    .navbar-light .navbar-toggler {
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
      }

      span {
        margin-top: -26%;
      }

    }

    a {
      color: #fff !important;
    }
  }

  a {
    color: inherit !important;
    text-decoration: none;
  }

  .change-lang {
    margin-left: 0 !important;

    ul {
      display: flex;
      justify-content: right;
      font-size: 0.4rem;
      margin-top: 0.5rem;
      list-style-type: none;
      margin-left: -1rem;

      span {
        color: white;
        display: block;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .mob-change-lang {
    @extend .change-lang;
    margin-top: 1.5rem !important;

    ul {
      margin-left: -4rem;
      font-size: 0.6rem;

      li {

        font-family: "The Sans Arab Light";
      }
    }
  }


  .category-title {
    margin: 0;
    padding: 0;
    color: #7d7d7d !important;
    text-decoration: none !important;
    list-style-type: none;
  }

  .main-navigation-container {
    position: fixed;
    top: initial;
    right: initial;
    bottom: initial;
    left: initial;
    z-index: initial;
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-align: center;
    height: 2.1rem;
    display: flex;
    width: 100%;

    ul,
    li,
    .category-subtitle {
      margin: 0;
      padding: 0;
      color: #7d7d7d !important;
      text-decoration: none !important;
      list-style-type: none;

      &:hover {
        color: #ec202d;
      }
    }

    .change-lang {
      margin-top: -0.5rem;

      ul {
        display: flex;
        // justify-content: space-evenly;
        font-size: 0.4rem;
        margin-top: 0.5rem;

        li:first-child {
          font-family: "The Sans Arab Light";
        }
      }

      span {
        color: #fff !important;

        :hover {
          cursor: pointer;
        }
      }
    }
  }

  .main {
    width: 100%;
    position: relative;
    margin: 0 auto;
  }

  nav {
    width: 90%;
    position: relative;
    margin: 0 auto;
  }

  .nav-position {
    align-items: center;
    height: 140px;
  }

  .navbar--hidden {
    top: -250px !important;
  }

  .main-navigation-container {
    direction: ltr;
    z-index: 1065;
    margin: 0;
    border: 0 none;
    -webkit-box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
  }

  .on-scroll-main-navigation-container {
    background-color: #fff;
    height: 90px;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
  }

  .search-title {
    float: right;
  }

  .navigation-items {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: initial
  }

  .navigation-items ul {
    display: contents;
  }

  .navigation-items ul li {
    font-family: "UniversLTStd";
    text-transform: uppercase;
  }

  .navigation-items>ul>li {
    float: left;
    font-weight: bold;
    padding: 0;
    height: 100px;
    display: flex;
    align-items: center;
  }

  .navigation-items>ul>li.sub-svg:hover {
    background: linear-gradient(to bottom, rgba(245, 245, 245, 0.1) 0%, #f6f6f6 60%);
  }

  .navigation-items>ul>li>a {
    // line-height: 3rem;
    padding: 0 10px;
    position: relative;
    z-index: 6;
    top: 0;
  }

  .navigation-items>ul>li a {
    font-weight: bold;
    font-size: 0.35rem;
  }

  .submenu-container {
    background-size: 100%;
    position: absolute;
    right: 13.7vw;
    min-width: 50vw;
    background-repeat: no-repeat;
    padding: 1rem;
    opacity: 0;
    visibility: hidden;
    display: block;
    text-align: center;
    background-image: url("../../../assets/img/submenu2.svg");
    top: 1.8rem;
  }

  .navigation-items>ul>li:hover>a+.submenu-container {
    opacity: 1;
    visibility: visible;
    transform: rotateY(0);
    height: 8vw;
    padding: 1%;
  }

  .navigation-items>ul>li:hover>a,
  .navigation-items>ul>li:focus>a {
    color: #ec202d !important;
  }

  .list {
    display: inline-block;
    padding-left: 1rem;
  }

  .category-title h2 {
    font-family: "Seravek";
    color: #7d7d7d;
    display: inline-block;
    font-size: 1.125rem;
  }

  .submenu li a {
    font-size: 0.9rem;
    line-height: 1.8rem;
    color: #555;
  }

  .submenu li a:hover {
    color: #ec202d;
    border-bottom: 1px dotted #03596d;
  }

  .main-navigation-container::after,
  .main-navigation-container::before {
    content: "";
    display: block;
  }

  .main-navigation-container::after {
    clear: both;
  }

  .searchbox {
    text-align: right;
    margin: 0.5vw 0 0 0;
    padding-right: 5px;

    i {
      font-size: 0.5rem;
      color: #fff;
      right: 0;
      margin-right: 0.4rem;
    }

    input {
      font-size: 0.5rem;
      width: 50%;
      font-family: "Seravek";
      color: #F0F0F0;
      border: 0;
      outline: 0;
      background: transparent;
      border-bottom: 0.125em solid rgba(240, 240, 240, 82%);
      line-height: 0.8rem;
      padding: 0;
    }

    input[type="search"]::placeholder {
      opacity: 0.5;
      color: #E0E0E0;
    }
  }

  .logo {
    height: 100%;

  }

  .mobile-nav {
    #mobile-nav-bg {
      background-size: 100vw 23vw;
      height: 7vw;
      width: 100vw;
      z-index: 4;
      position: fixed;
    }

    z-index: 1010;

    nav {
      position: fixed;
      transition: top 0.5s;
      background-color: #fff;
      top: 0;
      z-index: 999;
      height: 9vw;
      -webkit-box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
      box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
    }

    .navbar-brand {
      width: 40%;
      z-index: 5;
    }

    .navbar-collapse {
      background-color: #4FA1C4;
      color: #fff;
      opacity: 0.95;
      position: absolute;
      right: 0;
      top: 0;
      padding: 6rem 0.6rem;
      width: 38vw;
      font-size: 0.6rem;
      max-height: 100vh;
      overflow-y: scroll;
      z-index: 3;

      .dropdown-menu {
        min-width: auto;
      }

      .dropdown-item {
        padding: 0.1rem;
      }

      a {
        padding: 0.5rem 0;
        font-size: 0.8rem;
        font-weight: 100;
        border-bottom: 0.5px rgba(255, 255, 255, 0.5) solid;
      }

      .nav-link {
        padding: 1rem 0;
      }
    }

    .logo {
      margin-left: -7%;
      top: 0;
      position: absolute;
    }

    .navbar {
      margin: 0px;
      padding: 0px 10%;
      width: 100%;
    }

    button {
      padding: 5px;
      margin: 5px;
    }

    .navbar-light .navbar-toggler {
      border: none;
      z-index: 5;
      position: absolute;
      top: -5%;
      right: 2%;

      span {
        width: 6vw;
      }

      &.collapsed {
        filter: invert(1);
      }

      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(18,18,18,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      }

    }
  }

  .fa-calculator {
    margin-right: 5px;
  }
}

.video-container-full {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}