
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - .02, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}


@media screen and (min-width: 767px) {
  .arrow-mobile-on {
    display: none;
  }

  .brochure-mob-on {
    display: none;
  }

  .amb-brochure-mobile-on {
    display: none !important;
    margin-bottom: 1.3rem !important;
  }

  .mob-space-brochure {
    padding: 2rem 2.5rem 2rem 2.5rem !important;
  }
}

/* Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    #rectangle .logo {
      width: 5rem;
    }
  }
}

/* Safari 10.1 */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
    #rectangle .logo {
      width: 5rem;
    }
  }
}
/* Safari 7.1+ */
_::-webkit-full-page-media,
_:future,
:root .safari_only {
  #rectangle .logo {
    width: 5rem;
  }
}

// for xs and sm screens
@media screen and (max-width: 767px) {
  p {
    font-size: 0.8rem !important;
  }

  .mobile-pt {
    padding-top: 1.2rem;
  }

  #rectangle .mobile-nav .navbar-brand {
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  #rectangle .mobile-nav .logo {
    margin-left: -7%;
    margin-top: 2%;
    height: 80%;
  }

  .about .scale .scale-content p,
  .parent .colors-div .colors-scale-content p,
  .parent .amb-div .ambiance-scale-content p,
  .home-margin .scale .scale-content p,
  .inspiration-boxx .scale .scale-content p {
    visibility: visible !important;
  }

  .pt-40 {
    padding-top: 0 !important;
  }

  .amb-item-content .scale-content p {
    margin-bottom: 0;
  }

  .scale-content1 {
    padding: 7vw 4vw 7vw 0;
  }

  .parent .colors-div {
    margin-top: 0.5rem;
  }

  .amb-div {
    margin-top: 0.5rem;
  }

  .prod-title-mob {
    margin-bottom: 1.5rem;
  }

  .api-data section p {
    font-size: 0.7rem !important;
  }

  p span {
    font-size: 0.7rem !important;
  }

  .custom-tabs .react-tabs__tab-list {
    font-size: 1rem;
    text-align: center !important;
  }

  .interior-bg img {
    height: 13rem;
  }

  .about .about-content {
    padding-top: 150%;
  }

  .color-cells {
    margin: auto !important;
    /*   margin-bottom: -7.5vw !important; */
  }

  .no-icon {
    display: block;
    height: 12vw;
  }

  .scale-content1 {
    padding: 0vw 4vw 6vw 0;
  }

  .scale-content2 p {
    padding: 5vw 0 5vw 5vw;
  }

  .scale-content3 p {
    padding: 0 0 0 5vw;
  }

  .color-cell {
    padding-top: 0.3rem;
  }

  .Brochure {
    padding: 9vw 0 0vw 5vw;
  }

  .big-title .title-left {
    margin-top: 0 !important;
  }

  .bg,
  .vase {
    animation: none !important;
    /*     left: 45px !important;
    top: 15px !important; */
  }

  .mob-space-brochure {
    padding: 2.5rem 2.5rem 1.5rem 2.5rem !important;
  }

  .white-rose {
    animation: none !important;
  }

  .insp-full-img {
    height: 65vw !important;

    img {
      top: 25px !important;
    }
  }

  .lamp3,
  .selector-full,
  .selectors {
    display: none !important;
  }

  .insp-page-title {
    margin: 30% 6% !important;

    h1 {
      font-size: 1.2rem !important;
    }

    p {
      font-size: 0.6rem !important;
    }
  }

  .search-input i {
    font-size: 10pt !important;
  }

  .white-title {
    color: #fff !important;
  }

  .colors-items-area {
    padding-bottom: 0;
  }

  .amb-item-title h1 {
    line-height: 1.4rem !important;
    margin-top: 3.5rem !important;
    margin-bottom: 0.25rem !important;
  }

  .small-images-container {
    display: none !important;
  }

  .main-navigation-container {
    display: none !important;
  }

  .about-text {
    margin-top: 30% !important;
  }

  .blog-tabs {
    padding: 0 !important;
    padding-top: 1rem !important;
  }

  .amb-brochure {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .amb-item-bg {
    height: 50vw;

    .amb-item-title {
      h1 {
        font-size: 1.5rem;
        margin-top: 2rem;
      }

      p {
        font-size: 0.7rem !important;
      }
    }
  }

  .scale-amb-item-img {
    margin-bottom: 0.25rem;
  }

  .mob-space {
    padding: 2rem 2.5rem 0 2.5rem !important;
  }

  .blog-tabs .content {
    h1 {
      font-size: 2rem;
    }

    h6 {
      font-size: 1rem;
    }

    p {
      font-size: 0.5rem;
    }
  }

  .color-pscho .container-fluid {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .color-pscho .slider-container {
    width: 100%;
  }

  .custom-tabs .react-tabs__tab-list li {
    font-size: 0.6rem !important;
  }

  .color-description {
    .title {
      .selec-color {
        margin-left: 1.5rem;
      }
    }
  }

  .colors {
    .slider-container {
      height: 40vw;
      margin-top: 15%;
    }
  }

  .copyright {
    font-size: 0.4rem !important;
    margin-left: -1.5rem;
  }

  .footer-position {
    padding: 8% 4% 4% 14% !important;
  }

  .footer_ul_amrc a {
    font-size: 0.7rem;
    line-height: 1.1rem !important;
  }

  .footer h5 {
    font-size: 0.65rem;
  }

  .grid-container-padding {
    padding-right: 15px;
    padding-left: 15px;
  }

  .circle-group {
    height: 100vw;
    margin-top: 0.5rem;
  }

  .amb-brochure-mobile-off {
    display: none !important;
  }

  .brochure-mob-off {
    display: none !important;
  }

  .arrow-mobile-off {
    display: none;
  }

  .arrow-mobile-on {
    img {
      margin-left: -10px;
    }

    span {
      padding-left: 18px;
    }
  }

  .homepage-carousel {
    .carousel-inner {
      height: 166vw;
    }

    .room3 {
      height: 60%;
      width: initial;
      top: 42%;
    }

    .mob {
      // display: block;
      visibility: visible;
    }

    .desktop {
      // display: none;
      visibility: hidden;
    }
  }

  .homepage-carousel {
    .carousel-fade .carousel-item {
      height: 165vw !important;

      .slider-vh-left {
        top: 33%;
      }
    }
  }

  .side-img {
    position: inherit;
    width: 100%;
    margin: 0;
  }

  .slide-component-small {
    height: 20rem;
  }

  .about-text {
    margin-top: 30%;
  }
}

// extra small
@media screen and (max-width: 575px) {
  #rectangle .mobile-nav .navbar-brand {
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  #rectangle .mobile-nav .logo {
    margin-left: -7%;
    margin-top: 2%;
    height: 80%;
  }

  #trends .big-title {
    height: 50vw;

    .title-left h1 {
      font-size: 2rem;
    }
  }

  .about-text {
    h1 {
      text-align: center;
      font-size: 2rem;
    }
  }

  .color-cells {
    margin: auto !important;

    margin-bottom: -8.5vw !important;
  }

  .about-margin {
    margin-top: 3vw;
    width: 100%;
  }

  .color-trend-content .color-cell {
    padding-top: 0.2rem;
    height: 1.8rem;
    font-size: 0.5rem;
  }

  .color-description {
    margin-bottom: 2rem;
  }

  .title-center {
    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
    }
  }

  .slider-dots {
    top: 87% !important;
  }

  .about-us-slider .slide-component {
    height: 20rem;

    .about-us-info-text {
      width: 100%;
      margin-top: 0.5vw;
      margin: auto;
      line-height: 1.1rem;
    }

    .arrow-right {
      margin-left: 35vw !important;
      margin-top: 0.5vw;
    }
  }

  .inspcontentheader {
    margin: 0;
  }

  .city-select {
    .Select-multi-value-wrapper {
      margin-bottom: -0.2rem !important;
      height: 1.2rem;
    }

    .Select-placeholder {
      margin-top: -0.4rem !important;
    }
  }

  .colors .slider-container {
    margin-top: 15%;
  }

  .scale-responsive {
    margin-bottom: 1.5rem;
  }

  .Select-arrow-zone {
    width: 22px;
    height: 13px;
    margin-top: -2px;
    top: 27%;
  }

  .about-margin {
    /* margin-top: 0;
    width: 100%;
    margin-left: 2rem; */
    margin-top: 3vw;
    width: 100%;

    p {
      line-height: 0.7rem;
    }
  }

  .title-center {
    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
    }
  }

  .overlay .container-fluid {
    width: 100%;
  }

  .about-us-slider .slide-component {
    .about-us-info-text {
      width: 100%;
      margin-top: 0.5vw;
      margin: auto;
      line-height: 1.1rem;
    }

    .arrow-right {
      margin-left: 35vw !important;
      margin-top: 0.5vw;
    }
  }

  .change-bg .box-border-text .row-2 {
    font-size: 1.5rem;
    justify-content: center;
    width: 100%;
  }

  .product-slider {
    display: none;
  }

  .boxx {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 0.5rem;
  }

  .big-title {
    height: 40vw;
    padding-top: 25vw;
  }

  //Stores Page
  .stores-container .container-fluid {
    padding: 0rem;
    padding-bottom: 2rem;
    width: 95%;
  }

  //Decorative Finishes
  .frame-yt #amb_Features {
    margin: 0;
  }

  .store-container .tabs-container {
    padding: 0rem 2% 0 11%;
  }

  .stores-container .filter-container {
    margin-top: 3rem;
  }
}

//  small
@media screen and (min-width: 576px) and (max-width: 1000px) {
  .title-center {
    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }

  .Select-arrow-zone {
    top: 5px;
    width: 22px;
    height: 15px;
  }

  .about-margin {
    p {
      line-height: 0.7rem;
    }
  }

  // Stores Page
  .stores-container .container-fluid {
    padding: 0rem;
    padding-bottom: 2rem;
    width: 95%;
  }

  .stores-container .filter-container {
    margin-top: 3rem;
  }

  //Decorative Finishes
  .frame-yt #amb_Features {
    margin: 0;
  }

  .product-slider {
    display: none;
  }

  .boxx {
    grid-template-columns: repeat(1, 1fr);
  }

  .big-title {
    height: 40vw;
    padding-top: 25vw;
  }

  .insp-page-title {
    margin-top: 26%;
    margin-right: 46%;
  }

  // About-us Page
  .about-text {
    h1 {
      text-align: center;
      font-size: 2rem;
    }
  }

  .about-text {
    .slider-dots {
      top: 87%;
    }

    margin-top: 30%;
  }
}

// Medium
@media screen and (min-width: 768px) and (max-width: 991px) {
  #rectangle .logo {
    height: 65%;
    margin-top: 9%;
  }

  .big-title {
    height: 40vw;
    padding-top: 14vw;
  }

  .parent .colors-div {
    margin-top: 0.5rem;
  }

  .colors .slider-container {
    height: 60vw;
  }

  .brush-row {
    margin-top: 8%;
  }

  .amb-div {
    margin-top: 0.5rem;
  }

  .title-center {
    h1 {
      font-size: 3rem;
    }

    p {
      font-size: 1.5rem;
    }
  }

  .grid-container-padding {
    padding-right: 15px;
    padding-left: 15px;
  }

  .scale-responsive {
    margin-bottom: 1.5rem;
  }

  // Stores page
  .stores-container .container-fluid {
    padding: 0rem;
    padding-bottom: 2rem;
    width: 100%;
  }

  .stores-container .filter-container {
    margin-top: 3rem;
  }

  .carousel-item h1 {
    font-size: 2rem;
  }

  .carousel-item p {
    font-size: 1rem;
  }

  .carousel-fade .carousel-item.active {
    // height: 55.5vw !important;
  }

  //Decorative Finishes
  .frame-yt #amb_Features {
    margin: 0;
  }

  .product-slider {
    display: none;
  }

  .boxx {
    grid-template-columns: repeat(1, 1fr);
  }

  .mobile-nav {
    display: none;
  }

  .mini-tab-mobile {
    li {
      width: 25% !important;
    }
  }

  .amb-item-title,
  .interior-bg {
    h1 {
      font-size: 2rem !important;
    }

    p {
      font-size: 1rem !important;
    }
  }

  .blog-tabs .content {
    h1 {
      font-size: 2.5rem;
    }

    h6 {
      font-size: 1.5rem;
    }

    p {
      font-size: 0.6rem;
    }
  }

  .inspiration-sub-menu {
    padding-left: 6vw;
  }

  .products-sub-menu {
    padding-left: 20vw;
  }

  .circle-group {
    display: none;
  }

  .about .about-content {
    padding-top: 70%;

    .slider-dots {
      top: 70%;
    }
  }
}

// Large
@media screen and (min-width: 992px) and (max-width: 1199px) {
  #rectangle .logo {
    margin-left: -7%;
    margin-top: 5%;
    height: 80%;
  }

  #trends .big-title {
    height: 30vw;
    padding-top: 8vw;

    .title-left {
      padding-top: 2vw;
    }
  }

  .custom-tabs .react-tabs__tab {
    padding: 0.1rem 0.5rem;
  }

  .parent .colors-div {
    margin-top: 0.5rem;
  }

  .amb-div {
    margin-top: 0.5rem;
  }

  .title-center {
    margin-top: 11%;

    h1 {
      font-size: 3rem;
    }

    p {
      font-size: 1rem;
    }
  }

  .carousel-item h1 {
    font-size: 4rem;
  }

  .carousel-item p {
    font-size: 1rem;
  }

  .carousel-fade .carousel-item.active {
    // height: 55.5vw !important;
  }

  .homepage-product-slider .product-slider-small {
    display: none;
  }

  .mobile-nav {
    display: none;
  }

  .amb-item-title,
  .interior-bg {
    h1 {
      font-size: 2.5rem !important;
    }

    p {
      font-size: 1.2rem !important;
    }
  }

  .inspiration-sub-menu {
    padding-left: 9vw;
  }

  .products-sub-menu {
    padding-left: 20vw;
  }

  // Colors Page

  .colors {
    .slider-container {
      height: 30vw;
    }
  }

  .slider-dots {
    top: 48%;
  }
}

@media (min-width: 768px) and (max-width: 1361px) {
  .city-select {
    .Select-multi-value-wrapper {
      margin-bottom: -0.5rem !important;
    }

    .Select-placeholder {
      margin-top: -0.1rem !important;
    }
  }

  .search-input input {
    padding-left: 0.6rem !important;
  }
}

// Extra large
@media screen and (min-width: 1200px) {
  .title-left {
    padding-left: 9% !important;
    margin-top: 14% !important;
    margin-bottom: 5%;
  }

  .title-center {
    margin-top: 11%;

    h1 {
      font-size: 2.6rem;
    }

    p {
      font-size: 1rem;
    }
  }

  .carousel-item h1 {
    font-size: 5rem;
  }

  #rectangle .logo {
    margin-top: 5% !important;
  }

  .carousel-item p {
    font-size: 2rem;
  }

  .carousel-fade .carousel-item.active {
    // height: 55.5vw !important;
  }

  .search-input input {
    padding-left: 0.6rem !important;
  }

  .homepage-product-slider .product-slider-small {
    display: none;
  }

  .mobile-nav {
    display: none;
  }

  .amb-item-title,
  .interior-bg {
    h1 {
      font-size: 2.5rem !important;
    }

    p {
      font-size: 1.2rem !important;
    }
  }

  .inspiration-sub-menu {
    // padding-left: 15vw;
  }

  .products-sub-menu {
    padding-left: 30vw;
  }

  .colors {
    .slider-container {
      height: 30vw;
    }
  }
}
