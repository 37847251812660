@import "../../responsive.scss";

.compare-page-container {
    overflow-x: auto;
    padding: 0 10%;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;

    .compare-table {
        width: 100%;
        background-color: #fff;

        .table-heading {
            font-size: .8rem;
            font-weight: bold;
            color: #5E5E60;
            padding: 12px 40px !important;
        }

        .specifications {
            height: 90px;
            background-color: #BDBDBD;
            font-weight: 500;
            font-size: 24px;

            td {
                color: #fff;
            }
        }

        .products-container {
            td {
                padding: 0;
            }
        }

        tbody {
            font-size: 14px;

            tr:nth-child(even) {
                background-color: #FAFAFA;
                border-top: 1px solid #E1E1E1;
                border-bottom: 1px solid #E1E1E1;
            }

            tr {
                td:first-child {
                    font-weight: 800;
                }
            }
        }

        td {
            width: 25%;
            padding: 22px 40px;
            font-weight: 400;
            color: #5E5E60;
        }


    }

    .back-to-button {
        border: 1px solid #929292;
        border-radius: 2px;
        padding: 11px 20px 8px 20px;
        color: #5E5E60;
        text-decoration: none;
        font-size: 14px;
    }

    .product-box {
        margin-bottom: -70px;
        margin-right: 10px;
        margin-left: 10px;
        height: 330px;

        @include media-breakpoint-down(md) {
            width: 130px;
            height: 200px;
            padding: 10px;

            .product-name {
                white-space: initial;
                overflow: initial;
                text-overflow: initial;
                margin-bottom: 20px;
            }
        }

        .product-desc {
            height: 150px;

            p {
                display: block;
                text-align: center;
                font-size: .45rem;
                margin-bottom: .5rem;
            }
        }

        .product-img {
            margin: 0 0 30px 0;
            height: 170px;

            @include media-breakpoint-down(md) {
                margin: 5px;
                height: 120px;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .add-product {
            display: flex;
            justify-content: center;

            .add-product-btn {
                border: 1px solid #FF0000;
                border-radius: 2px;
                padding: 6px 10px;
                background-color: #fff;
                text-decoration: none;
                color: #5E5E60;

                img {
                    padding-right: .15rem;
                    padding-left: .15rem;
                }
            }
        }
    }
}

.product-box {
    height: 403px;
    width: 258px;
    border-radius: 2px;
    border: 1px solid #E1E1E1;
    position: relative;
    z-index: 2;
    background-color: #fff;
    padding: 18px;
    margin-bottom: .5rem;
    margin-top: .5rem;


    .product-actions {
        display: flex;
        justify-content: space-between;

        button {
            font-size: .8rem;
            color: #929292;
            border: 0;
        }
    }

    .product-img {
        display: flex;
        justify-content: center;
        margin: -5px 0 0 0;
        height: 150px;

        a {
            display: contents;

            img {
                height: 100%;
            }
        }

    }

    .product-name {
        font-weight: 900;
        font-size: 16px;
        color: #212529;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        a {
            text-decoration: none;
            color: inherit;
        }

        .product-number {
            font-size: 12px;
        }
    }

    .product-desc {
        // padding-left: 10px;
        font-weight: 400;
        color: #929292;
        font-size: .5rem;
        overflow: auto;
        height: 90px;
        overflow: hidden;

        ul {

            // * {
            //     display: none;
            // }

            // display: block;

            li {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
                color: #929292;
                display: block;

                &:before {
                    content: "- ";
                }
            }

            // >:nth-child(1),
            // >:nth-child(2),
            // >:nth-child(3) {
            //     font-size: 14px;
            //     color: #929292;
            //     white-space: nowrap;
            //     display: block;
            //     margin: 0;
            //     text-align: inherit;

            //     &:before {
            //         content: "- ";
            //     }
            // }
        }
    }

    .favorite {
        color: #FF0000 !important;
    }
}