.boxxx {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.interior-bg {
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  .interior-title {
    position: absolute;
    top: 65%;
    left: 10%;
    transform: translate(0, -50%);
    width: 65%;

    h1 {
      font-family: "UniversLTStd light";
      color: rgba(255, 255, 255, 0.84);
      text-transform: uppercase;
      margin: 0;
      line-height: 2rem;
    }

    p {
      font-family: "UniversLTStd light";
      font-size: 0.8rem;
      color: #ffffff;
      margin: 0;
    }
  }
}

.minus-margin {
  margin-top: -300px;
}

.radio {
  input {
    position: absolute;
    pointer-events: none;
    visibility: hidden;
  }

  input:focus+label {
    background: #c18b8e;

    .checker {
      border-color: #ec202d;
    }
  }

  input:checked+label {
    .checker {
      box-shadow: inset 0 0 0 6px #ec202d;
    }
  }

  label {
    display: flex;
    align-items: center;
    height: 28px;
    border-radius: 14px;
    margin: .5rem 0 0 .2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      .checker {
        box-shadow: inset 0 0 0 2px #ec202d;
      }
    }
  }

  .checker {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
    box-shadow: inset 0 0 0 2px #ccc;
    transition: box-shadow 0.3s ease;
  }
}

@-webkit-keyframes content {
  from {
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes content {
  from {
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

//MEDIA QUERİES
@media (max-width: 1000px) {}

@media (max-width: 600px) {}

.insp-boxx {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.6rem;
}

.inspiration-big-img-div {
  display: flex;
}

.inspiration-big-img {
  width: 100%;
  height: auto;
}

.insp-boxxx {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.insp-boxxx img {
  width: 32%;
  height: auto;

  &:hover {
    cursor: pointer;
  }
}

.insp-boxxx img :last-child {
  margin-right: 0;
}

@media only screen and (max-width: 600px) {
  .insp-boxx {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

.insp-scale {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.insp-scale img.bg {
  width: 100%;
  -webkit-transition: 1.5s;
  transition: 1.5s;
}

.tabs-control {
  margin-top: 120px;
  padding-left: 4rem;
  padding-right: 4rem;
}

.insp1-overlay-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 1.5s;
  transition: 1.5s;
  outline: 1px solid rgba(255, 255, 255, 0.6);
  outline-offset: -30px;
}

.tab-button {
  font-family: "Seravek";
  font-size: 1.25rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding-left: 2rem;
  padding-right: 2rem;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  -ms-transform: skewX(20deg);
  /* IE 9 */
  -webkit-transform: skewX(20deg);
  /* Safari 3-8 */
  transform: skewX(20deg);

  p {
    -ms-transform: skewX(-20deg);
    /* IE 9 */
    -webkit-transform: skewX(-20deg);
    /* Safari 3-8 */
    transform: skewX(-20deg);
    margin: 0;
  }
}

.tablist {
  display: flex;
  justify-content: center;
  margin: 4rem 0 3rem 0;
  text-align: justify;
}

.tablist .type-tab-containt {
  margin-top: 50px;
}

.tabpanel {
  position: absolute;
  margin-top: 900px;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
}

.fit-height {
  align-items: center;
}

.insp-text-center {
  font-family: "UniversLTStd light";
  font-size: 0.5rem;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  color: black;

  .row,
  .col {
    padding: 0;
    margin: 0;
  }

  >div {
    padding: 0;
    margin: 0;
  }

  .col-md-12 {
    p {
      margin-bottom: 0.1rem;

      span {
        font-family: "UniversLTStd light";
        font-size: 0.6rem;
      }
    }
  }
}

.color1 {
  height: 100px;
  background-color: #004048;
  align-items: center;
  margin: 0.5rem;

  p {
    color: #ffffff;
    text-align: center;
    line-height: 100px;
  }
}

.color2 {
  height: 100px;
  background-color: #198b9b;
  margin: 0.5rem;

  p {
    color: #ffffff;
    text-align: center;
    line-height: 100px;
  }
}

.color3 {
  height: 100px;
  background-color: #a5b2b0;
  margin: 0.5rem;

  p {
    color: #ffffff;
    text-align: center;
    line-height: 100px;
  }
}

.color4 {
  height: 100px;
  background-color: #8c948d;
  margin: 0.5rem;

  p {
    color: #ffffff;
    text-align: center;
    line-height: 100px;
  }
}

.color5 {
  height: 100px;
  background-color: #d2dae0;
  margin: 0.5rem;

  p {
    color: #ffffff;
    text-align: center;
    line-height: 100px;
  }
}

.color6 {
  height: 100px;
  background-color: #0e210b;
  margin: 0.5rem;

  p {
    color: #ffffff;
    text-align: center;
    line-height: 100px;
  }
}

.color-combination {
  width: 65%;
  margin: auto;
  padding: 0;

  .colors-container {
    width: 100%;
    display: flex;
    margin-left: 0.2rem;
  }

  .insp-button-center {
    display: flex;
    justify-content: center;
    margin: 0;

    .combination-name {
      margin-right: 5px;
    }

    h3 {
      font-size: 0.5rem;
      margin-top: 0.5rem;
    }

    >div {
      padding: 0;
    }

    .combination-name {
      margin-right: 5px;
    }

    h3.clickable {
      font-size: 0.6rem;
      cursor: pointer;
      margin: 1rem 0 0 0;
    }

    .color-container {
      padding-bottom: 5px;
      padding: 0.2rem;

      &:hover {
        cursor: pointer;
      }
    }

    .color-code,
    .color-name {
      background: inherit;
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      filter: invert(1) grayscale(1) contrast(30);
    }

    .color-name {
      font-size: 0.4rem;
    }

    .color-code {
      font-size: 0.4rem;
      margin: 0;
    }
  }
}