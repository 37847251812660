.blog-tabs {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: relative;
  background: #ffffff;
  padding: 50px;
  width: 90%;
  height: auto;
  box-shadow: 0px 0px 15px rgba(211, 211, 211, 0.7);
  min-width: 240px;
}

.read-more-wrapper {
  margin: 20px 0px;
  width: 100%;
}

.learn-more-container {
  height: 1rem;
  margin-top: 1.5rem
}

.news-title {
  margin-top: 20px;

  a {
    color: inherit;
    text-decoration: none;
  }
}

.blog-tabs .content {
  margin-top: 1rem;
  align-items: center;

  h1,h2 {
    margin-top: 10px;
    font-family: 'UniversLTStd light';
    font-size: 1.2rem;
    color: #7d7d7d;
    margin-bottom: 0px;
    line-height: 1;
  }

  h6 {
    font-family: 'UniversLTStd light';
    font-size: 0.8rem;
    color: #7d7d7d;
  }

}

.blog-thumb {
  width: 90%;
  width: 100%;
  height: auto;
}