$scale_img: 1.1;
$animation_time: 2s;

.homepage-carousel {

  .slider-video {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }

  .img-fluid {
    width: 100%;
  }

  .img-wide {
    width: 150%;
  }

  .mob {
    visibility: hidden;
  }

  .desktop {
    visibility: visible;
  }

  .carousel-fade .carousel-item {
    display: block;
    position: relative;
    opacity: 0;
    transition: opacity 0.75s ease-in-out;
    width: 100%;
  }

  .carousel-fade .carousel-item.active {
    opacity: 1;
    position: relative;
    width: 100%;
    // height: 55.5 !important;
  }

  .carousel-item {
    height: 100vh !important;

    color: white;

    .slider-vh-left {
      top: 50%;

      h1 {
        font-size: 2.5rem;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    &:nth-of-type(6) .slider-vh-left h1 {
      font-size: 2rem;
    }
  }

  .carousel-item {
    img.unscaled {
      width: 100%;
    }

    p {
      font-size: 0.8rem;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    p.descBig {
      font-weight: 100;
      opacity: 0.75;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    a {
      color: inherit;
    }
  }

  .carousel-item {
    width: 100%;
  }

  .carousel {
    position: relative;
    top: 0;
    left: 0;
  }

  .carousel-control-prev {
    margin-right: 1.3vw;
    width: 7%;
  }

  .carousel-control-next {
    margin-left: 1.4vw;
    width: 7%;
  }

  .carousel .carousel-control-prev-icon {
    background-image: url("../../assets/img/homepage/icons/arrow-slider-left.svg");
    width: 100%;
    height: 2rem;
    cursor: pointer;
  }

  .carousel .carousel-control-next-icon {
    background-image: url("../../assets/img/homepage/icons/arrow-slider-right.svg");
    width: 100%;
    height: 2rem;
    cursor: pointer;
  }
}

.slider-vh-left {
  position: absolute;
  left: 10%;
  transform: translate(0%, -50%);
  z-index: 2;
}

.back {
  position: absolute;
  z-index: -1;
}

.slider-obj {
  position: absolute;
  width: 100%;
  height: auto;
  transition: $animation_time;
}

.front1 {
  position: absolute;
  top: -25%;
  left: 57%;
  width: 30%;
  height: auto;
}

.front2 {
  position: absolute;
  top: -25%;
  left: 79%;
  transform: translate(-50%, 59%);
  width: 15%;
  height: auto;
}

.front-2-2 {
  position: absolute;
  top: -25%;
  left: 89%;
  transform: translate(-50%, 59%);
  width: 15%;
  height: auto;
}

.full-img {
  width: 100%;
  height: auto;
}

.front-3-1 {
  position: absolute;
  top: 0%;
  right: 0;
}

.table-and-basket {
  height: auto;
  transition: 2s;
  opacity: 0;
  bottom: -50%;
  left: 20%;
  width: 70% !important;

  @media only screen and (max-width: 560px) {
    left: 10%;
    width: 93% !important;
  }
}

.table-and-basket-50 {
  bottom: -50%;
  opacity: 0;
}

.stand {
  width: 17% !important;
  bottom: -5%;
  right: -30%;
  opacity: 0;

}

.stand-30 {
  width: 17% !important;
  bottom: -5%;
  right: -30%;
  opacity: 0;
}

.basket {
  opacity: 0;
  left: -10%;
  margin-top: 13% !important;
  width: 20% !important
}

.basket-30 {
  left: -10%;
  margin-top: 15% !important;
  width: 20% !important;
  opacity: 0;
}

.front-3-2 {
  width: 100%;
}

.chair {
  left: -100%;
  opacity: 0;
}

.table-33 {
  opacity: 0;
  right: -33%;
}

.table {
  opacity: 0;
  right: -33%;
}

.gray-bg {
  opacity: 1;
  width: 150%;
  top: -42%;
  left: -26%;
}

.gray-bg-150 {
  opacity: 1;
  width: 150%;
  top: -13%;
  left: -22%;
}

.chair-100 {
  opacity: 0;
  left: -100%;
}

.lamb {
  top: -33%;
  opacity: 0;
}

.room2 {
  opacity: 0;
  left: -33%;
}

.slider-bg {
  width: 150%;
}

.active {
  .slider-bg {
    width: 100% !important;
    transition: $animation_time;
  }

  .gray-bg {
    opacity: 1;
    width: 100% !important;
    left: 0%;
    top: 0%;
    transition: $animation_time;
  }

  .animation {
    -webkit-transition: $animation_time;
    transition: $animation_time;
    animation: fadeIn ease $animation_time;
    -webkit-animation: fadeIn ease $animation_time;
    -moz-animation: fadeIn ease $animation_time;
    -o-animation: fadeIn ease $animation_time;
    -ms-animation: fadeIn ease $animation_time;
  }

  .bg-animation {
    -webkit-transition: $animation_time;
    transition: $animation_time;
    animation: scaleOut ease $animation_time;
    -webkit-animation: scaleOut ease $animation_time;
    -moz-animation: scaleOut ease $animation_time;
    -o-animation: scaleOut ease $animation_time;
    -ms-animation: scaleOut ease $animation_time;
  }

  .chair {
    opacity: 1;
    left: 0;
  }

  .basket {
    opacity: 1;
    margin-top: 13% !important;
    left: 15%
  }

  .table-and-basket {
    opacity: 1;
    bottom: -5%;
  }

  .stand {
    bottom: -5%;
    opacity: 1;
    right: 7%;

    @media only screen and (max-width: 560px) {
      right: 2%;
    }

  }

  .table {
    opacity: 1;
    right: 0;
  }

  .room2 {
    left: 0;
    opacity: 1;
  }

  .room3 {
    top: 42%;
    height: 60%;
  }

  .lamb {
    opacity: 1;
    top: 0;
  }
}

@keyframes scaleOut {
  0% {
    top: -42%;
    opacity: 1;
    left: -22%;
    width: 150%
  }

  100% {
    top: 0%;
    left: 0%;
    opacity: 1;
    width: 100%
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.side-img {
  position: absolute;
  right: 5%;
  width: 50%;
}