$scale_img: 1.1;
$scale_txt: 1.1;

body {
  font-size: 0.6rem;
  font-family: "UniversLTStd light";
}

.ambiance-overlay-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  outline: 1px solid rgba(255, 255, 255, 0.6);
  outline-offset: -1rem;
}


.boxxxx {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-gap: 0.4rem;
}

.amb-four {
  width: 100%;
  height: auto;
  max-height: 50vh;
}

.ambiance-h {
  font-size: 1rem;
  font-weight: 400;
  font-family: "UniversLTStd light";
}

@media screen and (max-width: 1023px) {
  .parent {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    gap: 0.4rem;
  }
}

.amb-div {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

@media screen and(min-width: 1024px) {
  .parent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    transition: all 1.5s;
    height: 1000px;
  }

  .amb-div {
    padding-right: 0px !important;
    padding-left: 0px !important;

    img.ambiance-bg {
      object-fit: cover;
      object-position: center;
      overflow: auto;
    }
  }

  .amb-div:nth-child(1) {
    grid-area: 1 / 1 / 4 / 4;
  }

  .amb-div:nth-child(2) {
    grid-area: 1 / 4 / 6 / 10;

    h2 {
      top: 27% !important;
    }

    p {
      top: 30% !important;
    }
  }

  .amb-div:nth-child(3) {
    grid-area: 1 / 10 / 6 / 13;

    h2 {
      top: 27% !important;
    }

    p {
      top: 30% !important;
    }
  }

  .amb-div:nth-child(4) {
    grid-area: 4 / 1 / 8 / 4;
  }

  .amb-div:nth-child(5) {
    grid-area: 6 / 4 / 10 / 8;

    h2 {
      top: 22.5% !important;
    }

    p {
      top: 27% !important;
    }
  }

  .amb-div:nth-child(6) {
    grid-area: 6 / 8 / 8 / 13;
  }

  .amb-div:nth-child(7) {
    grid-area: 8 / 1 / 10 / 4;
  }

  .amb-div:nth-child(8) {
    grid-area: 10 / 1 / 13 / 4;
  }

  .amb-div:nth-child(9) {
    grid-area: 10 / 4 / 13 / 8;
  }

  .amb-div:nth-child(10) {
    grid-area: 8 / 8 / 13 / 13;
  }

  .ambiance-h {
    font-size: 2vw;
    font-family: "UniversLTStd light";
  }

  .ambiance-overlay-bg {
    outline-offset: -1rem;
  }

  .ambiance-big-title {
    padding-bottom: 30%;
  }
}

.pdfdownload {
  color: #333;

  &:last-child {
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1281px) {
  .parent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    transition: all 1.5s;
    height: 1400px;
    gap: 0.4rem;
  }

  .amb-div {
    padding-right: 0px !important;
    padding-left: 0px !important;

    img.ambiance-bg {
      object-fit: cover;
      object-position: center;
      overflow: auto;
    }
  }

  .ambiance-h {
    font-size: 2vw;
    font-family: "UniversLTStd light";
  }

  .ambiance-big-title {
    padding-bottom: 20%;
  }
}

@media screen and (min-width: 1601px) {
  .parent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0.4rem;
    transition: all 1.5s;
    height: 1800px;
  }

  .amb-div {
    padding-right: 0px !important;
    padding-left: 0px !important;

    img.ambiance-bg {
      object-fit: cover;
      object-position: center;
      overflow: auto;
    }
  }

  .ambiance-h {
    font-size: 1rem;
    font-family: "UniversLTStd light";
  }
}

.parent {

  .amb-div:nth-child(1),
  .amb-div:nth-child(2),
  .amb-div:nth-child(3),
  .amb-div:nth-child(4),
  .amb-div:nth-child(5),
  .amb-div:nth-child(6),
  .amb-div:nth-child(7),
  .amb-div:nth-child(8),
  .amb-div:nth-child(9),
  .amb-div:nth-child(10) {
    position: relative;
    overflow: hidden;
  }

  .amb-div {
    img.ambiance-bg {
      width: 100%;
      height: 100%;
      -webkit-transition: 1.5s;
      transition: 1.5s;
    }

    .ambiance-scale-content {
      position: absolute;
      width: 80%;
      top: 0;
      text-align: center;
      color: #ffffff;
      height: 100%;
      margin: 0 10%;

      h2 {
        position: relative;
        top: 45%;
      }

      p {
        font-family: "UniversLTStd light";
        font-size: 0.6rem;
        visibility: hidden;
        position: relative;
        top: 44%;
      }
    }
  }

  .dynamic-img:hover {
    .ambiance-overlay-bg {
      background-color: rgba(7, 7, 7, .3);
    }
  }

  .amb-div:hover {
    cursor: pointer;

    img.ambiance-bg {
      -webkit-transform: scale($scale_img);
      -ms-transform: scale($scale_img);
      transform: scale($scale_img);
    }

    .ambiance-scale-content {
      h2 {
        -webkit-transform: scale($scale_txt);
        -ms-transform: scale($scale_txt);
        transform: scale($scale_txt);
        -webkit-transition: all 1.5s;
        transition: all 1.5s;
      }

      p {
        visibility: visible;
      }
    }
  }
}

.amb-item-bg {
  position: relative;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  display: flex;
  height: 30vw;

  img {
    width: 100%;
    height: auto;
  }

  .amb-item-title {
    font-family: "UniversLTStd light";
    padding-left: 6%;
    padding-top: 2rem;
    width: 100%;

    h1 {
      color: #fff;
      text-transform: uppercase;
      line-height: 1rem;
      margin-top: 3rem;
      font-weight: 600;
    }

    p {
      color: #ffffff;
      opacity: 0.7;
    }
  }
}

.minus-margin {
  margin-top: -300px;
}

.amb-item-content {
  position: relative;
  background-color: #ffffff;
  width: 100%;
  z-index: 2;
  align-items: center;

  .centered {
    align-items: center;

    .centered-content {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .p-bold {
    font-family: "UniversLTStd light";
    font-size: 1.875rem;
    font-weight: 700;
  }

  p {
    font-family: "UniversLTStd light";
    font-size: 1.625rem;
  }
}

.scale-amb-item-img {
  width: 100%;
  // overflow: hidden;
  position: relative;
}

.scale-amb-item-img img.bg {
  width: 100%;
  height: 100%;
  -webkit-transition: 1.5s;
  transition: 1.5s;
}

.scale-amb-item {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;

  .scale-content,
  .scale-content-left {
    width: 100%;
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(0, 0, 0);
    padding: 3.5rem;

    h3 {
      font-family: "UniversLTStd light";
      font-size: 1.875rem;
    }

    p,
    li {
      font-family: "UniversLTStd light";
      font-size: 0.7rem;
    }

  }
}

.ambConent2 {
  align-items: start !important;

  p {
    margin-bottom: 0.3rem;
  }
}

.overlay-amb-item-bg {
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  outline: 1px solid rgba(255, 255, 255, 0.6);
  outline-offset: -2rem;
}

.frame-yt {
  .frame-embed {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #amb_Features {
    width: auto;
    margin: auto;

    .header {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  ul li {
    font-size: 0.6rem;
    list-style-position: inside;
  }

  .boxx p {
    text-align: justify;
    margin-left: -21%;
    margin-bottom: 0.3rem;
  }
}

.one-icon-en {
  margin-left: -3% !important;

}

.one-icon-ar {
  margin-right: -3% !important;

}

.with-icons,
.no-icon {
  display: none
}

.amb-brochure {
  width: 100%;
  font-size: 0.3rem;
  padding: 0 0 0 0;
  font-family: "UniversLTStd light";
  margin-top: 2rem;

  h6 {
    font-size: 0.4rem;
    text-transform: capitalize;
    margin-bottom: 1.5rem;
  }

  img {
    margin-right: 0.2rem;
    margin-left: 0
  }
}