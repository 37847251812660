.pin {
  width: 40px;
  height: 40px;
  position: relative;


  &:hover {
    cursor: pointer;
  }

  .glc-hq {
    width: 50px;

    img {
      z-index: 100;
      position: relative;
    }

  }

  img {
    width: 40px;
    height: 40px;

    &:hover {
      cursor: pointer;
    }
  }
}


.info-window {
  position: relative;
  bottom: 130px;
  left: -85px;
  width: 220px;
  height: 80px;
  background-color: white;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  padding: 10px;
  font-size: 14px;
  border-bottom: 5px solid rgba(212, 32, 58, 1);
  z-index: 1000;

  .info-window-store-name {
    font-size: 16px;
    font-weight: bold
  }

  .info-window-get-direction {
    margin-top: 10px;
    margin-bottom: 10px;

    a {
      font-size: 14px;
      color: #d4203a
    }
  }

  .trinagle {
    width: 0;
    height: 0;
    position: absolute;
    top: 100%;
    left: 42%;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 16px solid #d4203a;
  }
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
  transition: ease-in;
  z-index: 150;
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-6px);
  }

  25% {
    opacity: 1;
    transform: translateY(6px);
  }


  50% {
    opacity: 1;
    transform: translateY(-3px);
  }

  75% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(0);
  }
}

.blue-circle {
  width: 35px;
  height: 35px;
  position: relative;
  border-radius: 50%;
  background-color: #18a1ca
}

.info-window-user-location {
  top: 10px
}