.search {

  .anti-big-title {
    width: -100% !important;
    height: -60vh !important;
    display: block !important;
    background-size: unset !important;
    align-items: center !important
  }

  .search-container {
    padding: 25px;
    font-size: 0.6rem;
    padding: 10px;
  }

  .search-content {
    margin-top: 20px;

    .search-input {
      border-bottom: 1px solid #8e8e8e;
      color: #8e8e8e;

      i {
        font-size: 18pt;
        font-weight: 100;
        line-height: initial;
        justify-content: center;
        align-items: center;
        display: inline-block;
        margin-left: -0.8em;
        height: 100%;
      }

      input {
        width: 90%;
        outline: 0;
        border: 0;
        font-size: 18pt;
        padding-left: 0.5rem;

        &::-webkit-input-placeholder {
          color: #888;
        }
      }
    }
  }

  .cat-name {
    padding: 5px 10px;
    cursor: pointer;
  }

  .cat-container:hover {
    background-color: #ddd;
    color: #fff;
  }

  .cat-title {
    padding: 10px;
    font-size: 16pt;
  }

  .result-count {
    padding: 5px 10px;
  }



  .cat-selected {
    background-color: #ababab;
    color: #fff;
  }

  .blog-tabs {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    position: relative;
    background: #ffffff;
    padding: 50px;
    width: 90%;
    height: auto;
    box-shadow: 0px 0px 15px rgba(211, 211, 211, 0.7);
    min-width: 240px;
  }

  .blog-tabs .content {
    margin-top: 1rem;
    align-items: center;

    h1 {
      margin-top: 10px;
      font-family: 'UniversLTStd light';
      font-size: 1.2rem;
      color: #7d7d7d;
      margin-bottom: 0px;
      line-height: 1;
    }

    h6 {
      font-family: 'UniversLTStd light';
      font-size: 0.8rem;
      color: #7d7d7d;
    }

  }

  .result-container {
    .store-result {
      padding: 0rem 2rem 0px 2rem;

      .phone,
      .email,
      .address {
        margin-bottom: 10px;
      }

      .store-title {
        font-weight: bold;
      }

      .store-details {
        padding: 10px 0px;
      }

      .direction-button-container {
        width: 30%;
      }

      button {
        background-color: #d4203a;
        border: 3px solid #d4203a;
        width: 100%;
        text-align: center;
        color: #ffffff;
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        margin: 1rem auto;
        background-color: rgba(0, 0, 0, .1);
        height: 1px;
      }
    }

    .color-result {
      .color-code {
        font-size: 0.5rem;
        opacity: 0.53;
        padding-left: 0;
      }

      .clickable {
        cursor: pointer;
      }
    }

    .products-result {
      margin-bottom: 1.5rem;
    }

    .products-result img {
      width: 70% !important;
    }

    .products-result .image {
      text-align: center;
    }

    .result {
      margin: 0px 0px 20px 0px;
    }

    .result-category-title {
      margin-top: 10px;
      font-size: 18pt;
      text-transform: capitalize;
    }

    .result-title {
      padding: 10px 0px;
      font-size: 15pt;
      font-weight: 700;
    }

    .result-title a {
      color: inherit;
      /* blue colors for links too */
      text-decoration: inherit;
      /* no underline */
    }

    .image img {
      width: 100%;
    }
  }


}